import React, { useEffect } from "react";
import styled from "styled-components";
import Header from "../components/header/index";
import FormularioBicicleterias from "../components/redDeBicicleterias/formulario";
import Beneficios from "../components/redDeBicicleterias/beneficios";
import MapBicicleterias from "../components/redDeBicicleterias/mapa";
import Footer from "../components/footer/index";
// Librerias
import Headroom from "react-headroom";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
  overflow: hidden;
`;

export default function RedDeBicicleteriasContainer() {
  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <Container>
      {/* Canonical VivoMarketing */}
      <head>
        <link
          rel="canonical"
          href="https://segurobici.com.ar/red-de-bicicleterias"
        />
      </head>
      {/* Header */}
      <Headroom>
        <Header></Header>
      </Headroom>
      {/* Formulario Forma Parte */}
      <FormularioBicicleterias></FormularioBicicleterias>
      {/* Beneficios */}
      <Beneficios></Beneficios>
      {/* Mapa de Bicicleterias */}
      <MapBicicleterias></MapBicicleterias>
      {/* Footer */}
      <Footer marcas="active"></Footer>
    </Container>
  );
}
