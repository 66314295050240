import React, { useEffect } from "react";
import Marcador from "../marcador/index";
import { BsCheckLg, BsWhatsapp } from "react-icons/bs";
import Container from "./styled";
import Sound from "react-sound";
import Animacion from "./animacion";
import Timbre from "./timbre.mp3";
// Custom Hooks
import { useScrollTop } from "../../../hooks/useScrollTop";
import { useConsulta } from "../../../hooks/useConsulta";
import { BiPhone } from "react-icons/bi";
import { TbMail } from "react-icons/tb";

export default function Contrataste({ planSelected, cotizacion, contacto }) {
  useScrollTop();
  useConsulta(planSelected, cotizacion);

  useEffect(() => {
    const scriptPixel = document.createElement("script");
    const scriptGoogleAds = document.createElement("script");

    scriptPixel.text = `fbq('track', 'Lead', {})`;
    scriptGoogleAds.text = `gtag('event', 'conversion', {'send_to': 'AW-959485109/n3ZACOqEjNEDELWpwskD'})`;

    document.body.appendChild(scriptPixel);
    document.head.appendChild(scriptGoogleAds);

    return () => {
      document.body.removeChild(scriptPixel);
      document.head.removeChild(scriptGoogleAds);
    };
  }, []);

  return (
    <Container>
      {/* Marcador */}
      <Marcador
        pasoUno=""
        pasoDos=""
        pasoTres=""
        pasosTerminados={true}
        titulo={"¡Completaste el formulario con éxito!"}
        pasoTresTexto={
          contacto
            ? "Consultá"
            : "Contratá"
        }
      ></Marcador>
      {/* Sonido */}
      <Sound
        url={Timbre}
        autoLoad={true}
        playStatus={Sound.status.PLAYING}
        volume={80}
      />
      {/* Contrataste Container */}
      <div className="contrataste-container opacidad04">
        {/* Animación Container */}
        <div id="animacion-container">
          <Animacion></Animacion>
        </div>
        {/* Texto Agradecimiento */}
        {/* Seguro Container */}
        <div id="seguro-container">
          <img alt="Logo Seguro" src={planSelected.logo}></img>
          <p>{planSelected.seguro}</p>
          <p>{planSelected.plan}</p>
        </div>
        <div className="texto-container">
          {contacto ? (
            <div>
              <p id="texto-contacto">
                Nos pondremos en contacto con vos a la brevedad para ayudarte
                con información sobre nuestras coberturas.
              </p>
              <p id="texto-contacto">
                ¡Muchas gracias por confiar en SeguroBici!
              </p>
            </div>
          ) : (
            <div>
              <p id="texto-contacto">
                Nos pondremos en contacto con vos a la brevedad para continuar
                el proceso.
              </p>
              <p id="texto-contacto">
                ¡Muchas gracias por confiar en SeguroBici!
              </p>
            </div>
          )}

          {contacto ? (
            <>
              <p id="texto-contacto" className="texto-agradecimiento">
                Atentamente,{" "}
                <strong className="texto-agradecimiento-area">
                  Área Comercial de SeguroBici
                </strong>
              </p>
            </>
          ) : (
            <>
              <p id="texto-contacto" className="texto-agradecimiento">
                Atentamente,{" "}
                <strong className="texto-agradecimiento-area">
                  Área de Emisión de SeguroBici
                </strong>
              </p>
            </>
          )}

          <div className="canal">
            {contacto ? (
              <>
                <a
                  id="wppCanal3Frist"
                  href="https://api.whatsapp.com/send?phone=541128061469&text=%C2%A1Hola,%20necesito%20atención%20de%20siniestros%20de%20SeguroBici!%20Ingresé%20desde%20la%20web."
                  target="_blank"
                >
                  <span>
                    <BsWhatsapp />
                  </span>
                  1162255399
                </a>
                <p>|</p>
                <a
                  href="https://api.whatsapp.com/send?phone=541162096951&text=%C2%A1Hola,%20necesito%20atención%20de%20siniestros%20de%20SeguroBici!%20Ingresé%20desde%20la%20web."
                  target="_blank"
                  id="boton-telefono"
                >
                  <span>
                    <BiPhone />
                  </span>
                  0810-220-2373 (opción 1)
                </a>
              </>
            ) : (
              <>
                {/* Botón WhatsApp 1 */}
                <a
                  id="wppCanal3Frist"
                  href="https://api.whatsapp.com/send?phone=541128061469&text=%C2%A1Hola,%20necesito%20atención%20de%20siniestros%20de%20SeguroBici!%20Ingresé%20desde%20la%20web."
                  target="_blank"
                >
                  <span>
                    <BsWhatsapp />
                  </span>
                  1128061581
                </a>
                <p>|</p>
                <a
                  href="https://api.whatsapp.com/send?phone=541162096951&text=%C2%A1Hola,%20necesito%20atención%20de%20siniestros%20de%20SeguroBici!%20Ingresé%20desde%20la%20web."
                  target="_blank"
                  id="boton-telefono"
                >
                  <span>
                    <BiPhone />
                  </span>
                  0810-220-2373 (opción 4)
                </a>
                <p>|</p>
                {/* Botón Mail */}
                <a id="boton-mail" href="mailto:siniestros@seguroweb.com.ar">
                  <span>
                    <TbMail />
                  </span>
                  emision@seguroweb.com.ar
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}
