export const PRODUCTOS_ID = [
    {
      id: 1,
      nombre: "Bicicleta",
    },
    {
      id: 44,
      nombre: "Bicicleta Eléctrica",
    },
    {
      id: 45,
      nombre: "Monopatin",
    },
    {
      id: 46,
      nombre: "Bicicleta (Delivery)",
    },
];