import React from "react";
// Estilos
import Container from "./styled";
// Componentes
import Redes from "./redes";
import Ssn from "./ssn";
import Seguros from "./seguros";
// Librerías
import { Link } from "react-router-dom";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import ReactGa from "react-ga";

export default function Footer({ link, marcas, marketing_url, pagina }) {
  let medicionClick = () => {
    ReactGa.event({
      category: "Redes Sociales",
      action: "Click",
    });
  };

  return (
    <Container pagina={pagina}>
      {/* Seguros */}
      {marcas == "active" && (
        <Seguros segurobici={false} marcas={marcas}></Seguros>
      )}
      <div id="border-gradient"></div>
      {/* Max Width Container */}
      <div id="max-width-container">
        {/* Logo Data Container */}
        <div id="logo-data-container">
          {/* Logo */}
          <Link to="/">
            <img
              alt="Logo de SeguroBici"
              src="/images/logos/segurobici.svg"
            ></img>
          </Link>
          {/* Data */}
          <div>
            <a href="tel:08102202373">0810-220-2373</a>
            <a href="tel:1152632581">(011) 5263-2581</a>
            <p>info@segurobici.com.ar</p>
            <p>Suipacha 245 1° Piso CABA</p>
          </div>
        </div>
        {/* Navs Container */}
        <div id="navs-container">
          {/* Columna */}
          <div className="navs-container-columna" id="mapa-del-sitio">
            {/* Titulo */}
            <p>Mapa del sitio</p>
            {/* Nav */}
            <nav>
              <li>
                <Link to="/">Inicio</Link>
              </li>
              <li>
                <a href="https://blog.segurobici.com.ar/" target="_blank">
                  Blog SeguroBici
                </a>
              </li>
              <li>
                <a
                  href="https://blog.segurobici.com.ar/mapa-del-sitio/"
                  target="_blank"
                >
                  Novedades de SeguroBici
                </a>
              </li>
              <li>
                <Link to="/referidos">Referidos</Link>
              </li>
              <li>
                <Link to="/red-de-bicicleterias">Red de bicicleterias</Link>
              </li>
            </nav>
          </div>
          {/* Columna */}
          <div className="navs-container-columna" id="bicicleterias">
            <p>Bicicleterías</p>
            <nav>
              <li>
                <a href="/bicicleterias/mundo-bike" target="_top">
                  Mundo Bike
                </a>
              </li>
              <li>
                <a href="/bicicleterias/b-invasion-bicicleta" target="_top">
                  B invasión Bicicleta
                </a>
              </li>
              <li>
                <a href="/bicicleterias/mix-bikes-san-luis" target="_top">
                  Mix Bike San Luis
                </a>
              </li>
              <li>
                <a href="/bicicleterias/giant-cordoba" target="_top">
                  Giant Córdoba
                </a>
              </li>
              <li>
                <a href="/bicicleterias/bike-shop-pacheco" target="_top">
                  Bike Shop Pacheco
                </a>
              </li>
            </nav>
          </div>
          {/* Columna */}
          <div className="navs-container-columna" id="notas-del-blog">
            <p>Notas del blog</p>
            <nav>
              <li>
                <a
                  href="https://blog.segurobici.com.ar/como-elegir-rodado-bici/"
                  target="_blank"
                >
                  ¿Cómo elegir el rodado de tu Bicicleta?
                </a>
              </li>
              <li>
                <a
                  href="https://blog.segurobici.com.ar/es-bueno-andar-en-bicicleta-todos-los-dias/"
                  target="_blank"
                >
                  ¿Es bueno andar en bicicleta todos los días?
                </a>
              </li>
              <li>
                <a
                  href="https://blog.segurobici.com.ar/5-influencers-instagram/"
                  target="_blank"
                >
                  5 Influencers del ciclismo en Instagram
                </a>
              </li>
              <li>
                <a
                  href="https://blog.segurobici.com.ar/puedo-asegurar-una-bicicleta-usada/"
                  target="_blank"
                >
                  ¿Puedo asegurar una bicicleta usada?
                </a>
              </li>
              <li>
                <a
                  href="https://blog.segurobici.com.ar/por-que-se-rompen-los-rayos-bici/"
                  target="_blank"
                >
                  ¿Por qué se rompen los rayos de la bici?
                </a>
              </li>
            </nav>
          </div>
          {/* Columna */}
          <div className="navs-container-columna" id="categorias-relevantes">
            <p>Categorías relevantes</p>
            <nav>
              <li>
                <a href="/seguro-de-monopatin-electrico" target="_top">
                  Seguro Monopatín Eléctrico
                </a>
              </li>
              <li>
                <a href="/seguro-de-bicicleta-electrica" target="_top">
                  Seguro Bici Eléctrica
                </a>
              </li>
              <li>
                <a href="/seguro-de-bicicleta-comercial" target="_top">
                  Seguro Bici (Uso comercial)
                </a>
              </li>
            </nav>
          </div>
          {/* Columna */}
          <div className="navs-container-columna" id="la-empresa">
            {/* Titulo */}
            <p>La empresa</p>
            {/* Nav */}
            <nav>
              <li>
                <a href="https://gys.com.ar/" target="_blank" rel="nofollow">
                  Página web
                </a>
              </li>
              <li>
                <Link to="/sobre-nosotros" rel="nofollow">
                  Sobre nosotros
                </Link>
              </li>
              <li>
                <Link to="/forma-parte" rel="nofollow">
                  Forma parte de SeguroBici
                </Link>
              </li>
            </nav>
          </div>
          {/* Columna */}
          <div className="navs-container-columna" id="ayuda">
            {/* Titulo */}
            <p>Ayuda y legales</p>
            {/* Nav */}
            <nav>
              <li>
                <Link to="/politicas-y-terminos" rel="nofollow">
                  Políticas y términos
                </Link>
              </li>
              <li>
                <Link to="/contacto">Contacto</Link>
              </li>
            </nav>
          </div>
        </div>
        {/* Arrepentimiento Baja Container */}
        <div id="arrepentimiento-baja-container">
          <div>
            <h4>
              Botón de <span>arrepentimiento</span>
            </h4>
            <p>¿Te arrepentiste de tu solicitud?</p>
            <a href="https://seguroweb.com.ar/arrepentimiento" target="_blank">
              Ingresá
              <span>
                <HiOutlineArrowLongRight />
              </span>
            </a>
          </div>
          <div>
            <h4>
              Botón de <span>baja</span>
            </h4>
            <p>¿Querés dar de baja un seguro?</p>
            <a href="https://seguroweb.com.ar/baja" target="_blank">
              Ingresá
              <span>
                <HiOutlineArrowLongRight />
              </span>
            </a>
          </div>
        </div>
      </div>
      {/* Redes Component */}
      <Redes></Redes>
      {/* SSN Component */}
      <Ssn></Ssn>
    </Container>
  );
}
