import * as React from "react";
import Container from "./styled";
import { Fade } from "react-awesome-reveal";

export default function Beneficios() {
  return (
    <Container>
      {/* Section Max Width Container */}
      <section>
        {/* Title Container */}
        <Fade delay={100} duration={800} className="title-container">
          {/* Title */}
          <h3>
            ¡Beneficios de <span>asegurar con nosotros</span>!
          </h3>
        </Fade>
        {/* Cards Container */}
        <div id="cards-container">
          {/* Card Container 1 */}
          <Fade delay={100} duration={800} className="card-container">
            <div id="card-data-container">
              <div id="icon-title-container">
                <img
                  src="/images/home/beneficios/asistencia-inmediata.svg"
                  alt="Escudo del isologo de SeguroMascotas con ícono de sirena dentro"
                ></img>
                <h4>
                  Asistencia<br></br>inmediata
                </h4>
              </div>
              <p>
                Nuestro servicio de asistencia 24/7 te garantiza tranquilidad y
                soluciones.
              </p>
            </div>
          </Fade>
          {/* Card Container 2 */}
          <Fade delay={100} duration={800} className="card-container">
            <div id="card-data-container">
              <div id="icon-title-container">
                <img
                  src="/images/home/beneficios/asistencia-especializada.svg"
                  alt="Escudo del isologo de SeguroMascotas con ícono de sirena dentro"
                ></img>
                <h4>
                  Asistencia<br></br>especializada
                </h4>
              </div>
              <p>
                Te acompañamos, asesoramos y brindamos soluciones ante un
                siniestro.
              </p>
            </div>
          </Fade>
          {/* Card Container 3 */}
          <Fade delay={100} duration={800} className="card-container">
            <div id="card-data-container">
              <div id="icon-title-container">
                <img
                  src="/images/home/beneficios/descuentos.svg"
                  alt="Escudo del isologo de SeguroMascotas con ícono de sirena dentro"
                ></img>
                <h4>
                  Descuentos y<br></br>muchas coberturas
                </h4>
              </div>
              <p>
                Aprovechá los descuentos y elegí tu forma de pago preferida,
                tarjeta o CBU.
              </p>
            </div>
          </Fade>
          {/* Card Container 4 */}
          <Fade delay={100} duration={800} className="card-container">
            <div id="card-data-container">
              <div id="icon-title-container">
                <img
                  src="/images/home/beneficios/cotizaciones.svg"
                  alt="Escudo del isologo de SeguroMascotas con ícono de sirena dentro"
                ></img>
                <h4>
                  Cotizaciones<br></br>al instante
                </h4>
              </div>
              <p>
                Cotizá tu vehículo en nuestra web y recibí una cotización al
                instante.
              </p>
            </div>
          </Fade>
        </div>
      </section>
    </Container>
  );
}
