import FormSection from "../../FormSection";
import { BiError, BiUser } from "react-icons/bi";
import InputBasic from "../../InputBasic";
import Container from "./styled";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../../Button";
import InputTextarea from "../../InputTextarea";
const ContactoForm = ({ formValues }) => {
  const {
    inputValues,
    handleChange,
    handleChangeExternal,
    handleValidation,
    formErrors,
  } = formValues;

  return (
    <Container>
      <form>
        <FormSection
          title="Datos Personales"
          description="Contanos de vos"
          icon={<BiUser />}
        />
        {/* Input Nombre */}
        <InputBasic
          label="Nombre"
          type="text"
          id="input-nombre"
          name="nombre"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.nombre}
          className={formErrors.nombre == null ? "" : "input-error"}
          error={formErrors.nombre}
          placeholder="Nombre"
        />
        {/* Input Email */}
        <InputBasic
          label="Email"
          id="input-email"
          name="email"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.email}
          className={formErrors.email == null ? "" : "input-error"}
          error={formErrors.email}
          placeholder="hola@email.com"
        />
        {/* Input Telefono */}
        <InputBasic
          label="Teléfono"
          type="number"
          id="input-telefono"
          name="telefono"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.telefono}
          className={formErrors.telefono == null ? "" : "input-error"}
          error={formErrors.telefono}
          placeholder="52632373"
        />
        <FormSection
          title="Asunto del mensaje"
          description="Contanos de vos"
          icon={<BiUser />}
        />
        <InputBasic
          label="Asunto"
          id="input-asunto"
          name="asunto"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.asunto}
          className={formErrors.asunto == null ? "" : "input-error"}
          error={formErrors.asunto}
          placeholder="Asunto del mensaje"
        />
        <FormSection
          title="Mensaje"
          description="Contanos tus dudas"
          icon={<BiUser />}
        />
        <InputTextarea
          label="Ingresá tu mensaje"
          id="input-mensaje"
          name="mensaje"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.mensaje ? inputValues.mensaje : ""}
          className={formErrors.mensaje == null ? "" : "input-error"}
          error={formErrors.mensaje}
          placeholder="Contanos tus dudas"
        />
        <Button type="submit" onClick={handleValidation}>
          Enviar<span class="material-symbols-outlined">mail</span>
        </Button>
      </form>
    </Container>
  );
};

export default ContactoForm;
