import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  width: 1500px;
  margin: ${(props) =>
    props.isReferidosHeaderOpen == true
      ? "168px 0px 0px 0px;"
      : "116px 0px 0px 0px"};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;

  hr {
    width: 100%;
    border: 0.5px solid #eaeaea;
    margin: 20px 0;
  }

  .aclaracion {
    font-size: 12px;
    color: var(--gris);
    line-height: 1.7;
  }

  // Formulario Titulo Container
  #form-titulo-container {
    width: 740px;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    z-index: 60;
  }

  // Titulos Logo Container
  #titulos-logo-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  // Bicicleteria Logo Container
  #bicicleteria-logo-container {
    max-width: 98px;
    max-height: 150px;
    margin: 0px 24px 0px 0px;
    padding: 16px 16px 16px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: var(--grisClaro2);
    border-radius: 16px;
  }

  // Bicicleteria Logo Container Image
  #bicicleteria-logo-container img {
    display: block;
    width: 100%;
    object-fit: cover;
  }

  // Titulos Container
  #titulos-container {
    width: ${(props) =>
      props.esBicicleteria == true ? "calc(100% - 154px)" : "100%"};
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Titulos
  #titulos-container h1,
  #titulos-container h3 {
    width: 100% !important;
    margin: 0px 0px 8px 0px;
    color: var(--negro);
    letter-spacing: -0.015em;
  }

  #titulos-container h3 {
    margin: 0px 0px 0px 0px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
  }

  // Formulario Container
  #form-container {
    width: 100%;
    margin: 56px 0px 0px 0px;
    padding: 32px 32px 32px 32px;
    display: flex;
    justify-content: center;
    position: relative;
    background-color: var(--blanco);
    border-radius: 12px;
    box-shadow: 0px 5px 30px var(--sombraAzul);
    border: 1px solid var(--grisClaro);
  }

  // Formulario
  form {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Inputs Container
  #inputs-container {
    width: 100%;
    margin: 4px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Input Container
  #inputs-container .input-container,
  #inputs-container .select-container {
    width: 48%;
    margin: 0px 0px 16px 0px;
    position: relative;
  }

  // Inputs and Select
  #inputs-container .input-container input,
  #inputs-container .select-container select {
    width: 100%;
    padding: 11px 11px 11px 11px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    font-weight: 400;
    color: #868686 !important;
    border: 1.7px solid var(--grisClaro);
    background-color: var(--blanco);
    -webkit-appearance: none;
    box-shadow: 0;
    transition: 0.2s all;
    border-radius: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 58 !important;

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

        // Firefox
        [type="number"] {
      -moz-appearance: textfield !important;
    }
  }

  // Inputs and Select Focus
  #inputs-container .input-container input:focus,
  #inputs-container .select-container select:focus {
    border: 1.7px solid var(--naranja100);
    outline: none;
    z-index: 59 !important;
  }


  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Select Container
  .select-container {
    position: relative;
    width: 100%;
    max-width: 330px;
    margin: 0px 0px 24px 0px !important;
  }

  // Select
  .select-container select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-block;
    width: 100%;
    padding: 0px 16px 0px 16px;
    margin: 0px 0px 0px 0px;
    height: 100%;
    cursor: pointer;
    outline: 0;
    border: 1px solid rgb(152, 152, 154, 0.6);
    font-style: normal;
    font-weight: normal;
    color: var(--gris);
    position: relative;
    transition: all 0.25s ease;
    z-index: 60;
    background-color: transparent;
    border-radius: 0;
    -webkit-appearance: none;
    box-shadow: 0;
    border-radius: 3px;
    font-size: 0.75em;
    text-transform: capitalize !important;
  }

  // Select Flecha Container
  .flecha-select-container {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 16px;
    top: calc(50% - 10px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    background-color: var(--blanco);
    z-index: 59;
    transition: 0.2s all;
  }

  // Select Arrow
  .select-container i {
    width: 7px;
    height: 7px;
    margin: -4px 0px 0px 0px;
    display: block;
    border-left: 3px solid rgb(152, 152, 154, 0.6);
    border-bottom: 3px solid rgb(152, 152, 154, 0.6);
    transform: rotate(-45deg);
    border-radius: 10%;
    transition: 0.3s all;
  }

  #inputs-container
    .select-container
    select:focus
    ~ .flecha-select-container
    i {
    margin: 3px 0px 0px 0px !important;
    transform: rotate(135deg) !important;
  }

  // Select default arrow
  .select-container select::-ms-expand {
    display: none;
  }

  // Select Option
  .select-container select * {
    color: var(--negro);
  }

  #input-nombre-container input,
  #input-apellido-container input {
    text-transform: capitalize !important;
  }

  #input-nombre-container,
  #input-apellido-container,
  #input-email-container,
  #select-vehiculo-container {
    width: 330px !important;
    max-width: 330px !important;
  }

  #input-codigo-postal-container,
  #input-codigo-area-container {
    width: 117px !important;
    max-width: 117px !important;
  }

  #select-provincia-container,
  #input-telefono-container {
    width: 197px !important;
    max-width: 197px !important;
  }

  // Input Error
  .input-error {
    border: 1px solid red !important;
  }

  // Valor de Bici Container
  #valor-de-bici-container {
    width: 100% !important;
    margin: 7px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }

  // Valor de Bici Container Texto
  #valor-de-bici-container p {
    margin: 0px 0px 11px 0px;
    color: var(--negro);
    font-size: 13px;
    font-weight: normal;
  }

  // Valor de Bici Container Valor
  #valor-de-bici-container p:nth-child(2) {
    color: var(--naranja100);
    font-weight: 900;
  }

  // Valor de Bici Container Valor Mínimo y Máximo
  #valor-de-bici-container p:nth-child(4),
  #valor-de-bici-container p:nth-child(5) {
    margin: 7px 0px 0px 0px;
    font-size: 13px;
    color: var(--gris);
  }

  // Valor de Bici Container Input
  #valor-de-bici-container input {
    width: 100%;
    padding: 0px 0px 0px 0px;
    margin: 4px;
    -webkit-appearance: none;
    --value: 50000;
    --min: 20000;
    --max: 500000;
  }

  // Valor de Bici Container Input Focus
  #valor-de-bici-container input:focus {
    outline: none;
  }

  // Valor de Bici Container Input Line
  #valor-de-bici-container input::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: var(--naranja100);
    cursor: pointer;
    border-radius: 25px;
    box-shadow: none;
    -webkit-appearance: none;
  }

  // Valor de Bici Container Input Line (Firefox)
  #valor-de-bici-container input::-moz-range-track {
    width: 100%;
    height: 8px;
    background: var(--naranja100);
    cursor: pointer;
    border-radius: 25px;
    border: 0;
  }

  // Valor de Bici Container Input Button
  #valor-de-bici-container input::-webkit-slider-thumb {
    width: 18px;
    height: 18px;
    margin-top: -4px;
    background-color: var(--blanco);
    cursor: pointer;
    border: 2.5px solid var(--naranja100);
    border-radius: 50px;
    -webkit-appearance: none;
  }

  // Valor de Bici Container Input Button (Firefox)
  #valor-de-bici-container input::-moz-range-thumb {
    width: 16px;
    height: 16px;
    margin-top: -4px;
    background-color: var(--blanco);
    cursor: pointer;
    border: 2.5px solid var(--naranja100);
    border-radius: 50px;
    -webkit-appearance: none;
  }

  // Valor de Bici Container Input Var Values
  input.sliderProgress {
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--value) - var(--min)) / var(--range));
    --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));
  }

  // Valor de Bici Container Input Line Progress
  input.sliderProgress::-webkit-slider-runnable-track {
    background: linear-gradient(var(--naranja100), var(--naranja100)) 0 /
        var(--sx) 100% no-repeat,
      var(--grisClaro) !important;
  }

  // Valor de Bici Container Input Line Progress (Firefox)
  #valor-de-bici-container input::-moz-range-progress {
    height: 8px;
    background: var(--naranja100);
    border-radius: 25px;
    border: 0;
  }

  // Botones Container
  #botones-container {
    width: 100%;
    margin: 24px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  #botones-container a {
    background-color: var(--blanco);
    color: var(--verde50);
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    text-decoration: none;
  }

  #botones-container a span {
    font-size: 17px;
    margin: 4px 8px 0px 0px;
  }

  #botones-container button {
    padding: 7px 24px 7px 24px;
    border-radius: 8px;
    border: 0px solid;
    color: var(--blanco);
    background-color: var(--naranja100);
    cursor: pointer !important;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  #botones-container button img {
    height: 17px;
    margin-left: 10px;
    transition: 0.4s all;
  }

  #botones-container button:hover {
    background-color: var(--naranja40);
  }

  #botones-container button:hover > img {
    transform: translate(10px);
  }

  // Animacion Container
  #animacion-container {
    width: calc(100% - 830px);
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    z-index: 40;
  }

  // Loading Container
  #loading-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: var(--blanco);
    border-radius: 12px;
    z-index: 59;
    animation-name: animation-loading;
    animation-duration: 0.1s;
    animation-iteration-count: initial;
    animation-timing-function: ease-out;
  }

  // Animación Entrada Loading
  @keyframes animation-loading {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  // Animación Loading
  #animacion-loading {
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    width: 1000px;
    position: relative;

    // Formulario Titulo Container
    #form-titulo-container {
      width: 530px;
    }

    // Formulario Container
    #form-container {
      padding: 25px 25px 25px 25px;
    }

    // Inputs y Selects
    #input-nombre-container,
    #input-apellido-container,
    #input-email-container,
    #select-vehiculo-container {
      width: 48.5% !important;
      max-width: 48.5% !important;
    }

    #input-codigo-postal-container,
    #input-codigo-area-container {
      width: 20% !important;
      max-width: 20% !important;
    }

    #select-provincia-container,
    #input-telefono-container {
      width: 26% !important;
      max-width: 26% !important;
    }

    // Animacion Container
    #animacion-container {
      width: calc(100% - 380px);
      margin: -150px 0px 0px 0px;
      position: absolute;
      top: 160px;
      right: -20px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    width: 680px;
    justify-content: center;

    // Bicicleteria Logo Container
    #bicicleteria-logo-container {
      margin: 0px 0px 30px 0px;
    }

    // Titulos Logo Container
    #titulos-logo-container {
      width: 100%;
      margin: 00px 0px 0px 0px;
      justify-content: center;
    }

    // Bicicleteria Logo Container
    #bicicleteria-logo-container {
      margin: 0px 0px 0px 0px;
    }

    // Titulos Container
    #titulos-container {
      width: 100%;
      margin: 24px 0px 0px 0px;
      justify-content: center;
    }

    // Titulos
    #titulos-container h1,
    #titulos-container h3 {
      text-align: center;
    }

    // Formulario Titulo Container
    #form-titulo-container {
      width: 100%;
      justify-content: center;
    }

    // Inputs y Selects
    #input-nombre-container,
    #input-apellido-container,
    #input-email-container,
    #select-vehiculo-container {
      width: 308px !important;
      max-width: 308px !important;
    }

    #input-codigo-postal-container,
    #input-codigo-area-container {
      width: 101px !important;
      max-width: 101px !important;
    }

    #select-provincia-container,
    #input-telefono-container {
      width: 193px !important;
      max-width: 193px !important;
    }

    // Animacion Container
    #animacion-container {
      display: none;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    width: 480px;

    // Inputs y Selects
    #input-nombre-container,
    #input-apellido-container,
    #input-email-container,
    #select-provincia-container,
    #select-vehiculo-container,
    #input-codigo-postal-container,
    #input-codigo-area-container,
    #input-telefono-container {
      width: 208px !important;
      max-width: 208px !important;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    width: 350px;

    // Inputs y Selects
    #input-nombre-container,
    #input-apellido-container,
    #input-email-container,
    #select-provincia-container,
    #select-vehiculo-container,
    #input-codigo-postal-container,
    #input-codigo-area-container,
    #input-telefono-container {
      width: 142px !important;
      max-width: 142px !important;
    }

    #botones-container {
      flex-direction: column-reverse;
    }

    #botones-container a {
      width: 100%;
      margin: 20px 0px 0px 0px;
      justify-content: center;
    }

    #botones-container button {
      width: 100%;
      padding: 12px 27px 12px 27px;
    }
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    width: 85%;

    // Titulos
    #titulos-container h1 {
      font-size: 30px;
    }

    #titulos-container h3 {
      font-size: 17px;
    }

    // Formulario Container
    #form-container {
      margin: 30px 0px 0px 0px;
    }

    // Inputs y Selects
    #input-nombre-container,
    #input-apellido-container,
    #input-email-container,
    #select-provincia-container,
    #select-vehiculo-container,
    #input-codigo-postal-container,
    #input-codigo-area-container,
    #input-telefono-container {
      width: 47.5% !important;
      max-width: 47.5% !important;
    }
  }

  // 350px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile350}) {
    width: 85%;

    // Inputs y Selects
    #input-nombre-container,
    #input-apellido-container,
    #input-email-container,
    #select-provincia-container,
    #select-vehiculo-container,
    #input-codigo-postal-container,
    #input-codigo-area-container,
    #input-telefono-container {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
`;

export default Container;
