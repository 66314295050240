import React, { useEffect } from 'react'
import SiniestroForm from '../../Forms/SiniestroForm'
import { useForm } from '../../../hooks/useForm';
import { useWizardSiniestros } from '../../../context/WizardSiniestrosProvider';
import dayjs from "dayjs"
import axios from "axios"
import url from "../../../utils/url";
import { crearSiniestrosReglas } from '../../../utils/formRules';
import { crearSiniestroForm } from '../../../utils/formInitialValues';
import { obtenerLocalidades, obtenerProvincias } from '../../../services/provincias';
import { useState } from 'react';
import { obtenerTiposRiesgo, postearArchivoSiniestro } from '../../../services/siniestros';
import { useFile } from "../../../hooks/useFile";
import { useSocket } from '../../../context/SocketProvider';
import BackButton from '../../BackButton'
import { useScrollTop } from '../../../hooks/useScrollTop';
import Poliza from '../../Poliza';
import FormSection from '../../FormSection';
import { BiFile } from "react-icons/bi";
import { obtenerProductos } from '../../../services/productos';

const DenunciaStep = () => {

  useScrollTop()
  const { poliza, prevStep } = useWizardSiniestros()
  const [provincias, setProvincias] = useState([])
  const [localidades, setLocalidades] = useState([])
  const [tiposRiesgos, setTiposRiesgos] = useState([])
  const [productos, setProductos] = useState([])
  const { inputValues, handleChange, formErrors, handleValidation, handleChangeExternal } = useForm(
    handleSubmit,
    crearSiniestrosReglas,
    crearSiniestroForm
  );
  const socket = useSocket()
  const [loading, setLoading] = useState(false)

  const { nextStep } = useWizardSiniestros()

  const { sendFiles } = useFile(handleChangeExternal);

  async function handleSubmit() {
    setLoading(true)
    const siniestroData = {
      fecha_siniestro: inputValues.fecha,
      tipo_siniestro: inputValues.tipo,
      poliza_siniestro: poliza.poliza,
      descripcion_asegurado_siniestro: inputValues.descripcion, // NUEVO CAMPO
      hora_siniestro: inputValues.hora, // NUEVO CAMPO
      calle_siniestro: inputValues.calle, // NUEVO CAMPO
      altura_siniestro: inputValues.altura, // NUEVO CAMPO
      provincia_siniestro: inputValues.provincia, // NUEVO CAMPO
      localidad_siniestro: inputValues.localidad, // NUEVO CAMPO,
      tipo_carga_siniestro: 2, // NUEVO CAMPO
      email_asegurado_siniestro: inputValues.email, // NUEVO CAMPO
      codigo_area_asegurado_siniestro: inputValues.codigo_area, // NUEVO CAMPO
      telefono_asegurado_siniestro: inputValues.telefono, // NUEVO CAMPO
      dni_siniestro: poliza.doc_numero,
      id_poliza_siniestro: poliza.id_poliza,
      asegurado_siniestro: `${poliza.nombre} ${poliza.apellido}`,
      compania_siniestro: poliza.id_compania,
      estado_siniestro: "Ingresado",
      prioridad_siniestro: "Baja",
      mail_siniestro: poliza.email,
      siniestralidad_siniestro: null,
      fecha_carga_siniestro: dayjs(new Date()).format("YYYY-MM-DD"),
      producto_siniestro: inputValues.producto,
      cobertura_siniestro: null,
      id_asegurado_siniestro: poliza.id_asegurado,
      ramo_siniestro: poliza.ramo,
      suma_pagada_siniestro: null,
      id_operacion: null,
      estado_base_siniestro: 1
    }

    const siniestro = await axios.post(`${url}/api/siniestros/crear`, siniestroData);
    if(siniestro.status === 200){

      // Archivos Autogestión
      const filtrarArchivos = Object.entries(inputValues).filter(
        ([key, value]) => key.includes("archivo") && value !== ""
      );

      const postArchivo = async (value) => {
        const filePath = await sendFiles(value);
        if (filePath) {
          const archivoSiniestro = {
            id_siniestro: siniestro.data.insertId,
            url_siniestro_adjunto: filePath[0].path,
            estado_siniestro_adjunto: 1,
          };
          const documentacion = await postearArchivoSiniestro(archivoSiniestro);
          return documentacion.data;
        }
      }

      await Promise.all(
        filtrarArchivos.map(async ([key, value]) => {
          if(Array.isArray(value)){
            await Promise.all(value.map(async (archivo) => {
              await postArchivo(archivo)
            }))
          } else {
            await postArchivo(value)
          }
        })
      );

      const notificacion = {
        url_pagina: `/Siniestro/${siniestro.data.insertId}`,
        nombre_icono: "siniestros",
        nombre_pagina: "Siniestros",
        notificacion: `Nueva denuncia de siniestro desde Seguro Bici: Póliza ${poliza.poliza} | DNI ${poliza.doc_numero} | Asegurado ${poliza.nombre} ${poliza.apellido}`,
        dia: new Date(),
        tipo: "siniestros"
      }
      socket.emit("notificacion-crm", notificacion);
      await axios.post(`${url}/api/notificaciones`, notificacion);

      nextStep()
    }   
  }

  useEffect(() => {
    const getProvincias = async () => {
      try {
        const provincias = await obtenerProvincias()
        const tipos_riesgos = await obtenerTiposRiesgo()
        const productos = await obtenerProductos()
        setProductos(productos)
        setProvincias(provincias)
        setTiposRiesgos(tipos_riesgos)
      } catch (error) {
        console.log(error)
      }
    }
    getProvincias()
  }, [])

  useEffect(() => {
    const getLocalidades = async () => {
      if(inputValues.provincia){
        try {
          const localidades = await obtenerLocalidades(inputValues.provincia)
          setLocalidades(localidades)
        } catch (error) {
          console.log(error)
        }
      }

    }
    getLocalidades()
  }, [inputValues.provincia])

  return (
    <div>
      <BackButton prevStep={prevStep} text="Volver a buscar póliza" />
      <FormSection icon={<BiFile />} title={"Póliza seleccionada"} description={<div className="grid-container"><Poliza poliza={poliza} /></div>}/>
      <SiniestroForm formValues={{ inputValues, handleChange, handleChangeExternal, handleValidation, formErrors, provincias, productos,localidades, tiposRiesgos, loading }} />
    </div>
  )
}

export default DenunciaStep