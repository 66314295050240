import React, { useState, useEffect } from "react";
// Estilos
import Container from "./styled";
// Librerías
import { BsUpload } from "react-icons/bs";
import { BiInfoCircle } from "react-icons/bi";
import { CiFileOn } from "react-icons/ci";
import { RiEyeLine } from "react-icons/ri";
import disableScroll from "disable-scroll";
// Componentes
import ModalEjemplo from "../modalEjemplo";
// Custom Hooks
import { useFile } from "../../../../hooks/useFile";
import { forwardRef } from "react";

const InputFile = forwardRef(({
  archivo,
  handleChangeExternal,
  texto,
  nombre,
  id,
  tituloInfo,
  requisitos,
  peso,
  error,
  imagenModal,
  tituloModal,
  textoModal,
  label
}, ref) => {
  // Estado para setear si esta abierto o no el modal de ejemplo
  const [modalEj, setModalEj] = useState(false);
  // Estado para setear si esta activo o no el scroll
  const [isDisable, setIsDisable] = useState(false);

  // Subir y borrar archivos
  const { onFileUpload, deleteFile } = useFile(handleChangeExternal);

  // Toggle para el modal de ejemplo
  const toggleModalEj = () => {
    setModalEj(!modalEj);
    disableScroll.off();
  };

  // Toggle para el scroll
  const toggleScroll = () => {
    setIsDisable(!isDisable);
  };

  return (
    <Container ref={ref}>
      {/* Input File Container */}
      <span className="input-label">{label}</span>
      <div id="input-file-container">
        {archivo ? (
          // Si el archivo existe, lo muestra con la opción de eliminarlo
          <div id="archivo-container">
            {error ? (
              <img
                alt="ícono check correcto"
                src={process.env.PUBLIC_URL + "/images/icons/error.svg"}
              ></img>
            ) : (
              <img
                alt="ícono check correcto"
                src={process.env.PUBLIC_URL + "/images/icons/check.svg"}
              ></img>
            )}
            <span>
              <CiFileOn></CiFileOn>
            </span>
            <p>{archivo.name}</p>
            <a
              id="archivo-boton"
              href={URL.createObjectURL(archivo.image)}
              target="_blank"
            >
              Ver
            </a>
            <a id="borrar-boton" onClick={() => deleteFile(nombre)}>
              Eliminar
            </a>
          </div>
        ) : (
          // Si el archivo no existe, muestra la opción de subirlo
          <div id="carga-container">
            {error && (
              <img
                alt="ícono check correcto"
                src={process.env.PUBLIC_URL + "/images/icons/error.svg"}
              ></img>
            )}
            {/* Label / Botón */}
              <label for={id}>   
                <span>
                  <BsUpload></BsUpload>
                </span>
                <p>{texto}</p>
                <p>.pdf</p>
              </label>
              {/* Input */}
              <input
                type="file"
                name={nombre}
                accept="application/pdf"
                id={id}
                onChange={(e) => onFileUpload(e)}
              ></input>
          </div>
        )}
      </div>
      {/* Requisitos Container */}
      <div id="requisitos-container">
        <h4>
          <span>
            <BiInfoCircle></BiInfoCircle>
          </span>
          {tituloInfo}
        </h4>
        <p>{requisitos}</p>
        <p id="texto-peso">{peso}</p>
        <a
          id="boton-ejemplo"
          onClick={() => {
            toggleScroll();
            toggleModalEj();
          }}
        >
          <span>
            <RiEyeLine />
          </span>
          Ver ejemplo
        </a>
      </div>
      {error && <span className="error-input">{error}</span>}
      {/* Modal Ejemplo */}
      {modalEj && (
        <ModalEjemplo
          imagen={imagenModal}
          titulo={tituloModal}
          texto={textoModal}
          handleClose={toggleModalEj}
          handleScroll={isDisable && toggleScroll}
        />
      )}
    </Container>
  );
})

export default InputFile
