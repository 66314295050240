import axios from "axios"
import url from "../utils/url";

export const obtenerTiposRiesgo = async () => {
    const tipos = await axios.get(`${url}/api/siniestros/tipos_riesgos`);
    const tiposList = tipos.data.map((tipo, index) => {
        return {
            'label': tipo.nombre_tipo_siniestro,
            'value': tipo.id_tipo_siniestro,
            'key': index + 1
        }
    })
    return tiposList
}

export const postearArchivoSiniestro = async (archivo) => {
    return await axios.post(`${url}/api/siniestros/documentacion`, archivo);
}
