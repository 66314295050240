import InputBasic from "../../InputBasic";
import InputFile from "../../InputFile";
import Container from "./styled";
import Switch from "react-switch";

const AutogestionForm = ({ formValues, inputsRef, compania, handleAccesorios, tieneAccesorios, tab, setTab }) => {
  const { inputValues, handleChange, handleChangeExternal, formErrors } = formValues;

  return (
    <Container>
      {/* Marca */}
      <InputBasic
        label="Marca"
        type="text"
        id="inputMarca"
        name="marca"
        onChange={handleChange}
        onFocus={handleChange}
        onBlur={handleChange}
        value={inputValues.marca}
        className={formErrors.marca == null ? "" : "input-error"}
        placeholder="Marca"
        ref={(element) => (inputsRef.current["marca"] = element)}
      />
      {/* Modelo */}
      <InputBasic
        label="Modelo"
        type="text"
        id="inputModelo"
        name="modelo"
        onChange={handleChange}
        onFocus={handleChange}
        onBlur={handleChange}
        value={inputValues.modelo}
        className={formErrors.modelo == null ? "" : "input-error"}
        placeholder="Modelo"
        ref={(element) => (inputsRef.current["modelo"] = element)}
      />

      {/* Rodado */}
      <InputBasic
        label="Rodado"
        type="number"
        id="inputRodado"
        name="rodado"
        onChange={handleChange}
        onFocus={handleChange}
        onBlur={handleChange}
        value={inputValues.rodado}
        className={formErrors.rodado == null ? "" : "input-error"}
        placeholder="Rodado"
        ref={(element) => (inputsRef.current["rodado"] = element)}
      />

      {/* Color */}
      <InputBasic
        label="Color"
        type="text"
        id="inputColor"
        name="color"
        onChange={handleChange}
        onFocus={handleChange}
        onBlur={handleChange}
        value={inputValues.color}
        className={formErrors.color == null ? "" : "input-error"}
        placeholder="Color"
        ref={(element) => (inputsRef.current["color"] = element)}
      />

      <div className="select-container" id="select-material-container">
        <label className="input-label" for="selectMaterial">
          Material
        </label>
        <select
          id="selectMaterial"
          name="material"
          value={inputValues.material}
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          className={formErrors.material == null ? "" : "input-error"}
          placerholder="Material"
          ref={(element) => (inputsRef.current["material"] = element)}
        >
          <option value="" disabled selected hidden>
            Material
          </option>
          <option value="Aluminio">Aluminio</option>
          <option value="Acero">Acero</option>
          <option value="Carbono">Carbono</option>
        </select>
        <div className="flecha-select-container">
          <i></i>
        </div>
        {formErrors.material && <span>{formErrors.material}</span>}
      </div>
      {/* Transmisión */}
      <div className="input-container" id="input-transmision-container">
        <label className="input-label" for="inputTransmision">
          Marca y mod. de transmisión
        </label>
        <input
          type="text"
          id="inputTransmision"
          name="transmision"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.transmision}
          className={formErrors.transmision == null ? "" : "input-error"}
          placeholder="Marca y mod. de transmisión"
          ref={(element) => (inputsRef.current["transmision"] = element)}
        ></input>
        {formErrors.transmision && <span>{formErrors.transmision}</span>}
      </div>
      {/* Número Serie */}
      <div className="input-container" id="input-numero-serie-container">
        <label className="input-label" for="inputNumeroSerie">
          Número de serie
        </label>
        <input
          type="text"
          id="inputNumeroSerie"
          name="inputNumeroSerie"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.inputNumeroSerie}
          className={formErrors.inputNumeroSerie == null ? "" : "input-error"}
          placeholder="Número de serie"
          ref={(element) => (inputsRef.current["inputNumeroSerie"] = element)}
        ></input>
        {formErrors.inputNumeroSerie && (
          <span>{formErrors.inputNumeroSerie}</span>
        )}
      </div>
      {/* Tamaño Cuadro (Solo si la comapañia es Prudencia Seguros) */}
      {compania == "Prudencia Seguros" && (
        <div className="select-container" id="select-tamaño-cuadro-container">
          <label className="input-label" for="tamañoCuadro">
            Tamaño del cuadro
          </label>
          <select
            id="tamañoCuadro"
            name="tamañoCuadro"
            value={inputValues.tamañoCuadro}
            onChange={handleChange}
            onFocus={handleChange}
            onBlur={handleChange}
            className={formErrors.tamañoCuadro == null ? "" : "input-error"}
            placerholder="Tamaño del cuadro"
            ref={(element) => (inputsRef.current["tamañoCuadro"] = element)}
          >
            <option value="" disabled selected hidden>
              Tamaño del cuadro
            </option>
            <option value="Chico">Chico</option>
            <option value="Mediano">Mediano</option>
            <option value="Grande">Grande</option>
          </select>
          <div className="flecha-select-container">
            <i></i>
          </div>
          {formErrors.tamañoCuadro && <span>{formErrors.tamañoCuadro}</span>}
        </div>
      )}

      {/* Autogestión Accesorios */}
      <div id="autogestion-accesorios">
        <div id="texto-switch-container">
          <p>¿Tiene accesorios agregados?</p>
          <Switch
            onChange={handleAccesorios}
            checked={tieneAccesorios}
            uncheckedIcon={false}
            checkedIcon={false}
            offColor="#f6f6f6"
            onColor="#0DB27F"
            activeBoxShadow="0 0 0px 0px #fff"
            className="switch-accesorios"
          />
        </div>
        {/* Accesorios */}
        {tieneAccesorios == true && (
          <div className="input-container" id="input-accesorios-container">
            <label className="input-label" for="inputAccesorios">
              Lista de accesorios
            </label>
            <input
              type="text"
              id="inputAccesorios"
              name="accesorios"
              onChange={handleChange}
              onFocus={handleChange}
              onBlur={handleChange}
              value={inputValues.accesorios}
              className={formErrors.accesorios == null ? "" : "input-error"}
              placeholder="Lista de accesorios"
              ref={(element) => (inputsRef.current["accesorios"] = element)}
            ></input>
            {formErrors.accesorios && <span>{formErrors.accesorios}</span>}
          </div>
        )}
      </div>
      {/* Autogestión Foto Video Container */}
      <div id="autogestion-foto-video-container">
        {/* Foto del vehículo */}
        <InputFile
          handleChangeExternal={handleChangeExternal}
          archivo={inputValues.archivo_bicicleta}
          texto="Foto del vehículo"
          nombre="archivo_bicicleta"
          label="Foto del vehículo"
          id="input-foto-bici"
          tituloInfo="Requisitos"
          requisitos="Vehículo completo, con una hoja sobre la rueda con la fecha actual
            manuescrita."
          peso="Hasta 2mb máx."
          imagenModal="/images/pasos/contrata/ejemplos/EjemploBiciConFecha.svg"
          tituloModal="Foto de bicicleta con fecha actual"
          textoModal="Necesitamos una fotografía de la bicicleta junto con una hoja adherida que muestre claramente la fecha actual en la que se envía la cotización. Utiliza la imagen de ejemplo como guía para la presentación."
          error={formErrors.archivo_bicicleta}
          ref={(element) => (inputsRef.current["archivo_bicicleta"] = element)}
        ></InputFile>
        {/* Foto del número de serie */}
        <InputFile
          handleChangeExternal={handleChangeExternal}
          archivo={inputValues.archivo_num_serie_bicicleta}
          texto="Foto del núm. de serie"
          nombre="archivo_num_serie_bicicleta"
          label="Foto del núm. de serie"
          id="input-foto-serie"
          tituloInfo="¿Dónde?"
          requisitos="Suele estar en la parte de abajo de los pedalos, generalmente grabado."
          peso="Hasta 2mb máx."
          imagenModal="/images/pasos/contrata/ejemplos/EjemploNumSerie.svg"
          tituloModal="Foto del número de serie de Bicicleta"
          textoModal="Necesitamos una fotografía donde sea visible el número de serie de la bicicleta. Por lo general, este número se encuentra debajo de los pedales, tal como se muestra en la imagen redonda de ejemplo. Asegúrate de capturar la imagen de manera similar a la ilustración redonda de ejemplo."
          error={formErrors.archivo_num_serie_bicicleta}
          ref={(element) =>
            (inputsRef.current["archivo_num_serie_bicicleta"] = element)
          }
        ></InputFile>
        {/* Foto del documento */}
        <InputFile
          handleChangeExternal={handleChangeExternal}
          archivo={inputValues.archivo_frente_dni}
          texto="Foto del DNI"
          nombre="archivo_frente_dni"
          label="Foto del DNI"
          id="input-foto-dni"
          tituloInfo="Solo el frente"
          requisitos="Solo necesitamos el frente de tu DNI para validar tu identidad."
          peso="Hasta 2mb máx."
          imagenModal="/images/pasos/contrata/ejemplos/dni.svg"
          tituloModal="Foto del frente de tu DNI"
          textoModal="Necesitamos una foto del frente de tu documento de identidad para poder validar tus datos. Sacá la foto con buena iluminación, sin sombras en una superficie plana y procura que todos los datos se vean con clariadad."
          error={formErrors.archivo_frente_dni}
          ref={(element) => (inputsRef.current["archivo_frente_dni"] = element)}
        ></InputFile>
        {/* Factura de los accesorios solamente si tiene los mismos */}
        {tieneAccesorios == true && (
          <InputFile
            handleChangeExternal={handleChangeExternal}
            archivo={inputValues.archivo_factura_accesorios}
            texto="Factura de accesorios"
            nombre="archivo_factura_accesorios"
            label="Factura de accesorios"
            id="input-factura-accesorios"
            tituloInfo="¿Tenés más de una factura?"
            requisitos={[
              "Si tenes más de una factura, unificalas en un solo archivo PDF.",
              <a href="https://www.ilovepdf.com/es/unir_pdf" target="_blank">
                Unir ahora
              </a>,
            ]}
            peso="Hasta 2mb máx."
            imagenModal="/images/pasos/contrata/ejemplos/accesorios.webp"
            tituloModal="Factura de accesorios"
            textoModal={
              <p className="texto-container">
                Necesitamos las facturas de todos los accesorios en formato PDF.
                En caso de tener más de una factura, puedes unificarlas a través
                del siguiente enlace.
                <a
                  href="https://www.ilovepdf.com/es/unir_pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Click Aquí
                </a>
                .
              </p>
            }
            error={formErrors.archivo_factura_accesorios}
            ref={(element) =>
              (inputsRef.current["archivo_factura_accesorios"] = element)
            }
          ></InputFile>
        )}
        {/* Video del vehículo (solo si el valor asegurado es de $500.000 para arriba) */}
        {localStorage.getItem("segurobici-sumaasegurada") >= 500000 && (
          <InputFile
            handleChangeExternal={handleChangeExternal}
            archivo={inputValues.archivo_video}
            texto="Video de la bicicleta"
            nombre="archivo_video"
            label="Video de la bicicleta"
            id="input-video-bici"
            tituloInfo="Requisitos"
            requisitos="Video del vehículo completo rotando por completo alrededor del mismo."
            peso="Hasta 5mb máx."
            error={formErrors.archivo_video}
            ref={(element) => (inputsRef.current["archivo_video"] = element)}
          ></InputFile>
        )}
      </div>
      {/* Autogestión Archivos */}
      <div id="autogestion-archivos">
        <p id="texto-factura">
          Subí la factura de tu vehículo, un presupuesto del mismo o una
          referencia en MercadoLibre.
        </p>
        {/* Tabs */}
        <div id="tabs-container">
          <a
            onClick={() => setTab("factura")}
            className={tab == "factura" && "tab-activa"}
          >
            <span></span>
            Factura
          </a>
          <a
            onClick={() => setTab("presupuesto")}
            className={tab == "presupuesto" && "tab-activa"}
          >
            <span></span>
            Presupuesto
          </a>
          <a
            onClick={() => setTab("meli")}
            className={tab == "meli" && "tab-activa"}
          >
            <img
              alt="Logo de MercadoLibre"
              src="/images/logos/mercadolibre.svg"
            ></img>
          </a>
        </div>
        {/* Archivo Valor Container */}
        <div id="archivo-valor-container">
          {tab == "factura" ? (
            // Factura del vehículo
            <InputFile
              handleChangeExternal={handleChangeExternal}
              archivo={inputValues.archivo_factura_bicicleta}
              texto="Factura de compra"
              nombre="archivo_factura_bicicleta"
              label="Factura de compra"
              id="input-factura"
              tituloInfo="Requisitos"
              requisitos="Se aceptan facturas solamente de hasta 3 meses desde su emisión."
              peso="Hasta 2mb máx."
              imagenModal="/images/pasos/contrata/ejemplos/EjemploFactura.svg"
              tituloModal="Factura de compra"
              textoModal="Se requiere la factura de compra del vehículo en formato PDF con una antigüedad no superior a 3 meses. En caso contrario, puede proceder a gestionar un presupuesto."
              error={formErrors.archivo_factura_bicicleta}
              ref={(element) =>
                (inputsRef.current["archivo_factura_bicicleta"] = element)
              }
            ></InputFile>
          ) : tab == "presupuesto" ? (
            // Presupuesto de la bicicleta
            <InputFile
              handleChangeExternal={handleChangeExternal}
              archivo={inputValues.archivo_presupuesto_bicicleta}
              texto="Presupuesto"
              nombre="archivo_presupuesto_bicicleta"
              label="Presupuesto"
              id="input-presupuesto"
              tituloInfo="Requisitos"
              requisitos="Se aceptan presupuestos solamente de hasta 3 meses desde su emisión."
              peso="Hasta 2mb máx."
              imagenModal="/images/pasos/contrata/ejemplos/EjemploCotizacion.svg"
              tituloModal="Presupuesto del vehículo"
              textoModal="Se requiere el presupuesto de no mas de tres meses del vehículo al que se quiere asegurar en formato PDF"
              error={formErrors.archivo_presupuesto_bicicleta}
              ref={(element) =>
                (inputsRef.current["archivo_presupuesto_bicicleta"] = element)
              }
            ></InputFile>
          ) : (
            // Input Link MercadoLibre
            <div className="input-container" id="input-mercadolibre-container">
              <label className="input-label" for="input-mercadolibre">
                Link de Mercado Libre (*)
              </label>
              <input
                type="text"
                id="input-mercadolibre"
                name="linkMercadoLibre"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.linkMercadoLibre}
                className={
                  formErrors.linkMercadoLibre == null ? "" : "input-error"
                }
                placeholder="Link de la publicación"
                ref={(element) =>
                  (inputsRef.current["linkMercadoLibre"] = element)
                }
              ></input>
              <div>
                <span className="info-input">
                  (*) No debe tener cuotas sin interés
                </span>
              </div>
              {formErrors.linkMercadoLibre && (
                <span>{formErrors.linkMercadoLibre}</span>
              )}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default AutogestionForm;
