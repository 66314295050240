import { useEffect } from "react";
import { postearConsulta } from "../services/contratacion";

export const useConsulta = (planSelected, cotizacion) => {

    useEffect(() => {
        const enviarMailConsulta = async () => {       
            try {
              if (planSelected.precio !== 0 && planSelected.precio !== "") {
                await postearConsulta(cotizacion.numero, planSelected.nombre_compania, planSelected.plan)    
              }          
            } catch (error) {
              console.log(error)
            }
        }
        enviarMailConsulta()
    }, [])
    
};