import { SwitchTransition } from "react-transition-group";
import { BsWhatsapp } from "react-icons/bs";
import { TbMail } from "react-icons/tb";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import Container from "./styled";

export default function Siniestros() {
  return (
    <Container>
      {/* Section Max Width Container */}
      <section id="max-width-container">
        {/* Tag Container */}
        <Fade delay={100} duration={800} className="tag-container">
          {/* Tag */}
          <div>
            <p>Siniestros</p>
          </div>
        </Fade>
        {/* Title Container */}
        <Fade delay={100} duration={800} className="title-container">
          {/* Title */}
          <h3>
            ¿Tuviste un <span>siniestro</span>?
          </h3>
        </Fade>
        {/* Texto Container */}
        <Fade delay={100} duration={800} className="text-container">
          <p>
            Denunciá tu siniestro hasta 72hs del hecho.
          </p>
        </Fade>
        {/* Tabs Container */}
        <Fade delay={100} duration={800} className="tabs-container">
        </Fade>
        {/* Switch Transition */}
        <Fade delay={100} duration={800} className="switch-container">
          <SwitchTransition mode={"out-in"}>
              {/* Consultas Container */}
              <div id="data-container">
                  <Link className="boton" to="/siniestros">Denunciar siniestro</Link>
                  <div className="canal">
                    {/* Botón WhatsApp 1 */}
                    <a
                      id="wppCanal3Frist"
                      href="https://api.whatsapp.com/send?phone=541128061469&text=%C2%A1Hola,%20necesito%20atención%20de%20siniestros%20de%20SeguroBici!%20Ingresé%20desde%20la%20web."
                      target="_blank"
                    >
                      <span>
                        <BsWhatsapp />
                      </span>
                      11-2806-1469
                    </a>
                    <p>|</p>
                    <a
                      href="https://api.whatsapp.com/send?phone=541162096951&text=%C2%A1Hola,%20necesito%20atención%20de%20siniestros%20de%20SeguroBici!%20Ingresé%20desde%20la%20web."
                      target="_blank"
                    >
                      <span>
                        <BsWhatsapp />
                      </span>
                      11-6209-6951
                    </a>
                    <p>|</p>
                    {/* Botón Mail */}
                    <a
                      id="boton-mail"
                      href="mailto:siniestros@seguroweb.com.ar"
                    >
                      <span>
                        <TbMail />
                      </span>
                      siniestros@seguroweb.com.ar
                    </a>
                  </div>
              </div>
          </SwitchTransition>
        </Fade>
        {/* Subtitle Container */}
        <Fade
          delay={100}
          duration={800}
          className="subtitle-container"
        >
          <p>
            Nuestro horario de atención es de 9hs a 18hs todos los días hábiles
          </p>
        </Fade>
      </section>
    </Container>
  );
}
