import * as React from "react";
import Container from "./styled";
import { Fade } from "react-awesome-reveal";

export default function Pasos({ landingVehiculo }) {
  return (
    <Container>
      <div id="max-width-container">
        {/* Paso 1 */}
        <Fade delay={100} duration={800} className="paso-container">
          <div>
            <img src="/images/home/pasos/1.svg" className="flotar"></img>
            <p className="titulo-paso">Paso 1</p>
            <p className="texto-paso">
              {landingVehiculo == "monopatin"
                ? "Solicitá la cotización de tu monopatín desde el formulario en nuestra web"
                : "Solicitá la cotización de tu bici desde el formulario en nuestra web"}
            </p>
          </div>
        </Fade>
        {/* Paso 2 */}
        <Fade delay={100} duration={800} className="paso-container">
          <div>
            <img src="/images/home/pasos/2.svg" className="flotar"></img>
            <p className="titulo-paso">Paso 2</p>
            <p className="texto-paso">
              Un asesor se pondrá en contacto con vos<br></br>(Días hábiles de 9
              a 18hs)
            </p>
          </div>
        </Fade>
        {/* Paso 3 */}
        <Fade delay={100} duration={800} className="paso-container">
          <div>
            <img
              src={
                landingVehiculo == "monopatin"
                  ? "/images/home/pasos/4.svg"
                  : "/images/home/pasos/3.svg"
              }
              className="flotar"
            ></img>
            <p className="titulo-paso">Paso 3</p>
            <p className="texto-paso">
              ¡Ya tenes tu poliza!<br></br>
              {landingVehiculo == "monopatin"
                ? "Salí a andar seguro y sin preocupaciones"
                : "Salí a pedalear seguro y sin preocupaciones"}
            </p>
          </div>
        </Fade>
      </div>
    </Container>
  );
}
