import styled, { css } from "styled-components";
import { breakpoints } from "../../../../constants/media";

export const Container = styled.div`
  width: 283.5px;
  height: 190px;
  margin: 40px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 10px;
  font-family: "Consolas", "Courier", monospace !important;
  background-color: var(--blanco);
  border: 1px solid var(--grisClaro);
  user-select: none !important;
  position: relative;
  transition: 0.6s all;

  // Fondo Tarjeta Curva
  #fondo-tarjeta-curva-uno {
    width: 120px;
    margin: 0px 0px 0px 0px;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
    user-select: none;
    z-index: 80;
  }

  #fondo-tarjeta-curva-dos {
    width: 120px;
    margin: 0px 0px 0px 0px;
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
    user-select: none;
    transform: rotate(180deg);
    z-index: 80;
  }

  // Nombre Contactless Container
  #nombre-contactless-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 24px 24px 0px 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: flex-start;
    z-index: 81;

    // Nombre
    p {
      width: 75%;
      max-width: 177px !important;
      margin: 0px 0px 0px 0px;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #656b81;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    // Image Contactless
    img {
      width: 16px;
      margin: 0px 0px 0px 0px;
      pointer-events: none;
      user-select: none;
    }
  }

  // Número Container
  #numero-container {
    width: 100%;
    height: 42px;
    margin: 0px 0px 0px 0px;
    padding: 0px 24px 0px 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-self: flex-end;
    z-index: 81;

    // Texto
    p {
      width: 100%;
      margin: 0px 0px 0px 0px;
      font-family: DM Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #656b81;
    }

    // Número y Texto Container
    div {
      width: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p:first-child {
        width: auto;
        margin: 0px 0px 0px 0px;
        color: #bab9b9;
        font-family: Consolas !important;
        font-size: 13px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: normal !important;
      }

      p:nth-child(2) {
        width: auto;
        margin: 0px 0px 0px 0px;
        font-family: DM Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #656b81;
      }
    }
  }

  // Vencimiento Logo Container
  #vencimiento-logo-container {
    width: 100%;
    height: 40px;
    margin: 0px 0px 0px 0px;
    padding: 0px 24px 24px 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-self: flex-end;
    z-index: 81;

    // Vencimiento Container
    div {
      width: 30%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;

      p:first-child {
        width: 100%;
        margin: 0px 0px 0px 0px;
        font-family: DM Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #656b81;
      }

      p:nth-child(2) {
        width: 100%;
        margin: 4px 0px 0px 0px;
        color: #bab9b9;
        font-family: Consolas;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    // Logo
    img {
      width: ${(props) => {
        switch (props.brand) {
          case "visa":
            return "60px";
          case "americanexpress":
            return "40px";
          case "mastercard":
            return "60px";
          case "maestro":
            return "50px";
          case "naranja":
            return "10px";
          case "dinersclub":
            return "70px";
          case "cabal":
            return "10px";
          case "discover":
            return "70px";
          case "unionpay":
            return "30px";
          case "jcb":
            return "50px";
          case "elo":
            return "50px";
          case "hipercard":
            return "50px";
          case "mir":
            return "70px";
          case "hiper":
            return "30px";
        }
      }};
      transition: 0.2s all;
      animation-name: logo-animation;
      animation-duration: 0.2s;
      animation-iteration-count: initial;
      animation-timing-function: ease-in-out;
      pointer-events: none;
    }

    @keyframes logo-animation {
      from {
        transform: scale(0.8);
        opacity: 0%;
      }
      50% {
        opacity: 50%;
      }
      to {
        transform: scale(1);
        opacity: 100%;
      }
    }
  }

  // 350px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile350}) {
    display: none;
  }
`;
