import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 96px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  border-top: 1px solid var(--grisClaro);

  // Max Width Container
  #max-width-container {
    width: 100%;
    max-width: 1270px;
    margin: 80px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }

  // Title
  h3 {
    width: 100%;
    margin: 0px 0px 32px 0px;
    text-align: center;
  }

  h3 span {
    color: var(--naranja100);
  }

  // Beneficios Container
  #beneficios-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: row;
  }

  // Beneficios Card
  #beneficios-container div {
    width: 33.3%;
    max-width: 350px;
    margin: 24px 0px 0px 0px;
    border-radius: 16px;
  }

  // Beneficio 4 y 6
  .beneficio-4 {
    margin: 24px -100px 0px 0px !important;
  }

  .beneficio-6 {
    margin: 24px 0px 0px -100px !important;
  }

  // Beneficios
  #beneficios-container div div {
    width: 100%;
    min-height: 185px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    background-color: var(--blanco);
    box-shadow: 0px 5px 30px var(--sombraNaranja);
    border: 1px solid var(--grisClaro);
    border-radius: 16px;
  }

  // Beneficio Titulo
  #beneficios-container div div h4 {
    width: 100%;
    max-width: 250px;
    min-height: 54px;
    margin: 32px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-size: 18px;
    text-align: center;
    line-height: 27px;
    color: var(--lila90);
  }

  // Beneficio Texto
  #beneficios-container div div p {
    max-width: 290px;
    margin: 16px 0px 0px 0px;
    text-align: center;
    line-height: 27px;
  }

  // Cotizar Botón
  a {
    margin: 80px 0px 0px 0px;
    padding: 12px 24px 12px 24px;
    border-radius: 8px;
    color: var(--blanco);
    background-color: var(--naranja100);
    cursor: pointer !important;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Cotizar Button Hover
  a:hover {
    background-color: var(--naranja40);
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Max Width Container
    #max-width-container {
      max-width: 1000px;
    }

    // Beneficios Card
    #beneficios-container div {
      width: 28%;
    }

    // Beneficio 4 y 6
    .beneficio-4 {
      margin: 24px -75px 0px 0px !important;
    }

    .beneficio-6 {
      margin: 24px 0px 0px -75px !important;
    }

    // Beneficios
    #beneficios-container div div {
      min-height: 212px;
    }

    // Beneficio Texto
    #beneficios-container div div p {
      max-width: 80%;
      margin: 13px 0px 30px 0px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    margin: 90px 0px 0px 0px;

    // Max Width Container
    #max-width-container {
      max-width: 680px;
    }

    // Beneficios Card
    #beneficios-container div {
      width: 31%;
    }

    // Beneficio 4 y 6
    .beneficio-4 {
      margin: 24px -52px 0px 0px !important;
    }

    .beneficio-6 {
      margin: 24px 0px 0px -52px !important;
    }

    // Beneficios
    #beneficios-container div div {
      min-height: 239px;
    }

    // Beneficio Texto
    #beneficios-container div div p {
      max-width: 85%;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    #max-width-container {
      max-width: 480px;
    }

    // Beneficios Card
    #beneficios-container div {
      width: 100%;
      max-width: 100%;
      margin: 40px 0px 0px 0px;
    }

    // Beneficio 4 y 6
    .beneficio-4 {
      margin: 40px 0px 0px 0px !important;
    }

    .beneficio-6 {
      margin: 40px 0px 0px 0px !important;
    }

    // Beneficios
    #beneficios-container div div {
      min-height: 160px;
    }

    #beneficios-container div:first-child {
      margin: 0px 0px 0px 0px;
    }

    // Beneficio Titulo Primero
    #primer-titulo {
      margin: 30px 0px 0px 0px !important;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    #max-width-container {
      max-width: 350px;
    }
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    // Max Width Container
    #max-width-container {
      max-width: 85%;
    }
  }
`;

export default Container;
