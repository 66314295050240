import React, { useContext, useEffect, useState } from 'react'
import { usePlanes } from '../hooks/usePlanes'
import { useSwitch } from '../hooks/useSwitch'

const SiniestrosContext = React.createContext()

export function useWizardSiniestros() {
  return useContext(SiniestrosContext)
}

export function SiniestrosProvider({ children }) {
  
  const [poliza, setPoliza] = useState(null)
  const { step, prevStep, nextStep } = useSwitch()

  const steps = [
    {
      titleStep: "Buscá",
      title: "Denunciá tu siniestro"
    },
    {
      titleStep: "Denunciá",
      title: "Describí el siniestro"

    },
    {
      titleStep: "Confirmá",
      title: "¡Denunciaste tu siniestro!"
    },
  ];

  const handlePoliza = (poliza) => setPoliza(poliza)

  return (
    <SiniestrosContext.Provider value={{ step, prevStep, nextStep, handlePoliza, poliza, steps }}>
      {children}
    </SiniestrosContext.Provider>
  )
}