import styled from "styled-components";
import { breakpoints } from "../../../../../../constants/media";

const Container = styled.nav`
  width: 100%;
  margin: 10px 0px 0px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;

  // Button
  button {
    width: auto;
    height: auto;
    padding: 0px;
    margin: 0px;
    border: 0px;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    cursor: pointer;
    margin: 0px 10px 0px 0px;
  }

  // Button Text
  p {
    text-align: center !important;
    color: var(--lightgray) !important;
    font-family: Raleway;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 15px !important;
    margin: 0px !important;
    transition: 0.1s all;
    line-height: 0px !important;
  }

  // Button Text Active
  .activeP {
    color: var(--orange) !important;
  }

  // Button Text Border Active
  .activeSpan {
    width: 100%;
    height: 2px;
    background-color: var(--orange);
    margin: 10px 0px 0px 0px;
  }

  // Button Text Hover
  button:hover > p {
    color: var(--black) !important;
  }

  // Button Border Hover
  button:hover > .activeSpan {
    background-color: var(--black) !important;
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    margin: 30px 0px 0px 0px;
    justify-content: center;

    // Button
    button {
      margin: 0px 5px 0px 5px;
    }
  }
`;

export default Container;
