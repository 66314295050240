import React from "react";
import { ContratacionProvider } from "../../context/WizardContratacionProvider";
import Wizard from "../../components/Wizard";
import ElegirPlan from "../../components/ContratacionStep/ElegirPlan";
import ContratacionPlan from "../../components/ContratacionStep/ContratacionPlan";
import ConfirmacionPlan from "../../components/ContratacionStep/ConfirmacionPlan";
import Header from "../../components/header";
import Headroom from "react-headroom";
import Footer from "../../components/footer";

const Planes = () => {


  const steps = [
    {
      title: "Cotizá",
    },
    {
      title: "Elegí",
    },
    {
      title: "Contratá",
    },
  ];

  return (
    <div>
      {/* Header */}
      <Headroom>
        <Header></Header>
      </Headroom>
      <ContratacionProvider>
        <Wizard steps={steps} title="¡Cotizaste tu seguro con éxito!">
          <ElegirPlan />
          <ContratacionPlan />
          <ConfirmacionPlan />
        </Wizard>
      </ContratacionProvider>
      <Footer marcas=""></Footer>
    </div>
  );
};

export default Planes;
