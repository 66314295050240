import * as React from "react";
// Estilos
import Container from "./styled";

export default function Card({ image, alt, title, text, link }) {
  return (
    <Container>
      {/* Card Container */}
      <div className="card-container">
        {/* Data Container */}
        <section>
          {/* Logo y Título Container */}
          <div id="logo-title-container">
            {/* Logo */}
            <img src={image} alt={alt}></img>
            {/* Botón */}
            <p>{title}</p>
          </div>
          {/* Texto y Botón Container */}
          <div id="text-button-container">
            {/* Texto */}
            <p>{text}</p>
            {/* Botón */}
            <a href={link} target="_blank">
              Cotizar
            </a>
          </div>
        </section>
      </div>
    </Container>
  );
}
