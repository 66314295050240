import InputBasic from "../../InputBasic";
import Container from "./styled";
import FormSection from "../../FormSection";
import { BiError, BiUser } from "react-icons/bi";
import InputFile from "../../InputFile";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../../Button";
import InputTextarea from "../../InputTextarea";

const FormaParteForm = ({ formValues }) => {
  const {
    inputValues,
    handleChange,
    handleChangeExternal,
    handleValidation,
    formErrors,
  } = formValues;

  return (
    <Container>
      <form>
        <FormSection
          title="Datos Personales"
          description="Contanos de vos"
          icon={<BiUser />}
        />
        {/* Input Nombre */}
        <InputBasic
          label="Nombre"
          type="text"
          id="input-nombre"
          name="nombre"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.nombre}
          className={formErrors.nombre == null ? "" : "input-error"}
          placeholder="Nombre"
          error={formErrors.nombre}
        />
        {/* Input Apellido */}
        <InputBasic
          label="Apellido"
          type="text"
          id="input-apellido"
          name="apellido"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.apellido}
          className={formErrors.apellido == null ? "" : "input-error"}
          placeholder="Apellido"
          error={formErrors.apellido}
        />
        {/* Input Email */}
        <InputBasic
          label="Email"
          type="email"
          id="input-email"
          name="email"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.email}
          className={formErrors.email == null ? "" : "input-error"}
          placeholder="hola@email.com"
          error={formErrors.email}
        />
        {/* Input Puesto */}
        <InputBasic
          label="Puesto"
          type="text"
          id="input-puesto"
          name="puesto"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.puesto}
          className={formErrors.puesto == null ? "" : "input-error"}
          placeholder="Marketing"
          error={formErrors.puesto}
        />
        {/* Input Linkedin */}
        <InputBasic
          label="LinkedIn"
          type="text"
          id="input-linkedin"
          name="linkedin"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.linkedin}
          className={formErrors.linkedin == null ? "" : "input-error"}
          placeholder="Link de Linkedin"
          error={formErrors.linkedin}
        />
        <FormSection
          title="Motivación"
          description="Contanos tus motivaciones"
          icon={<BiUser />}
        />
        <InputTextarea
          label="Ingresa tu mensaje"
          type="text"
          id="input-mensaje"
          name="mensaje"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.mensaje ? inputValues.mensaje : ""}
          className={formErrors.mensaje == null ? "" : "input-error"}
          error={formErrors.mensaje}
        />

        <FormSection title="CV" description="Adjuntá tu CV" icon={<BiUser />} />

        <InputFile
          handleChangeExternal={handleChangeExternal}
          archivo={inputValues.cv}
          texto="Archivo del CV"
          nombre="cv"
          label="Archivo del CV"
          id="input-archivo-cv"
          tituloInfo="Solo el frente"
          requisitos="Solo necesitamos el frente de tu DNI para validar tu identidad."
          peso="Hasta 2mb máx."
          imagenModal="/images/pasos/contrata/ejemplos/dni.svg"
          tituloModal="Foto del frente de tu DNI"
          textoModal="Necesitamos una foto del frente de tu documento de identidad para poder validar tus datos. Sacá la foto con buena iluminación, sin sombras en una superficie plana y procura que todos los datos se vean con clariadad."
          error={formErrors.cv}
          hideRequeriments={true}
        ></InputFile>
        <Button type="submit" onClick={handleValidation}>
          Enviar<span class="material-symbols-outlined">mail</span>
        </Button>
      </form>
    </Container>
  );
};

export default FormaParteForm;
