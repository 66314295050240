import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../components/header/index";
import Cotizador from "../components/cotizador";
import Logos from "../components/home/logos/index";
import Cubrimos from "../components/home/cubrimos/index";
import Estrellas from "../components/home/estrellas";
import Pasos from "../components/home/pasos/index";
import Referidos from "../components/home/referidos";
import CarouselBicicletas from "../components/home/carousel/index";
import Comunicacion from "../components/home/comunicacion";
import Beneficios from "../components/home/beneficios/index";
import Faqs from "../components/home/faqs/index";
import Footer from "../components/footer/index";
import Siniestros from "../components/home/siniestros";
// Librerias
import Headroom from "react-headroom";
import { useSEO } from "../hooks/useSEO";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
  overflow: hidden;
`;

export default function HomeContainer({ data }) {
  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  // Referidos Header
  const [isReferidosHeaderOpen, setReferidosHeaderOpen] = useState(true);
  const toggleReferidosHeader = () => {
    setReferidosHeaderOpen(!isReferidosHeaderOpen);
  };

  useSEO ({
    title: "SeguroBici - Cotizá tu Seguro de Bicicleta",
    description: "¡Cotizá tu seguro online! Coberturas todo riesgo, asistencia o traslado en caso de accidentes, robo de la bici o sus componentes, accesorios y documentos cubiertos."
  })

  return (
    <Container>
      {/* Canonical VivoMarketing */}
      <head>
        <link rel="canonical" href="https://segurobici.com.ar" />
      </head>
      {/* Header */}
      <Headroom>
        <Header
          isReferidosHeaderOpen={isReferidosHeaderOpen}
          toggleReferidosHeader={toggleReferidosHeader}
        ></Header>
      </Headroom>
      {/* Cotizador */}
      <Cotizador
        isReferidosHeaderOpen={isReferidosHeaderOpen}
        subtitle="Estas muy cerca de asegurar tu bici, sentite libre"
        marketing_url={data}
      ></Cotizador>
      {/* Logos */}
      <Logos></Logos>
      {/* Cosas que cubre el seguro */}
      <Cubrimos></Cubrimos>
      {/* Estrellas */}
      <Estrellas></Estrellas>
      {/* Pasos para contratar el seguro */}
      <Pasos></Pasos>
      {/* Referidos*/}
      <Referidos></Referidos>
      {/* Carousel */}
      <CarouselBicicletas></CarouselBicicletas>
      {/* Beneficios del seguro */}
      <Beneficios></Beneficios>
      <Siniestros></Siniestros>
      {/* Comunicacion */}
      <Comunicacion></Comunicacion>
      {/* Preguntas frecuentes */}
      <Faqs></Faqs>
      {/* Footer */}
      <Footer marcas="active" marketing_url={data}></Footer>
    </Container>
  );
}
