import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  width: 100%;
  max-width: 1920px;
  background-color: var(--blanco);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: green;
`;

export default Container;
