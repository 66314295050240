import React from 'react'
import ProgressBar from '../ProgressBar'
import { useSwitch } from '../../hooks/useSwitch'
import { useWizardContratacion } from '../../context/WizardContratacionProvider' 

const Wizard = ({ steps, title, children }) => {

  const { step } = useWizardContratacion()

  return (
    <div>
        <ProgressBar steps={steps} title={title} />
        {children[step - 1]}
    </div>
  )
}

export default Wizard