import styled from "styled-components";
import { breakpoints } from "../../../../constants/media";

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0px 0px 0px 0px;
  padding: 26px 28px 28px 28px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  border-radius: 12px;
  border: 1px solid var(--grisClaro);
  background-color: var(--blanco);

  // Usuario Container
  #usuario-container {
    width: 80%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    // Foto de perfil
    img {
      width: 28px;
      height: 28px;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      object-fit: contain;
      pointer-events: none;
      user-select: none;
    }

    // Nombre de usuario
    h4 {
      width: auto;
      margin: 0px 0px 0px 8px;
      padding: 0px 0px 0px 0px;
      font-weight: 500;
      font-size: 13px;
      color: var(--negro);
    }
  }

  // Estrellas Container
  #estrellas-container {
    width: 20%;
    margin: -2px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;

    // Número
    p {

      margin: 3px 1px 0px 0px;
      padding: 0px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 170%;
      text-align: left;
      letter-spacing: -0.015em;
      color: #cbe4ff;
      -webkit-font-smoothing: subpixel-antialiased;
    }

    // Estrella
    img {
      width: 16px;
      margin: 0px 0px 0px 4px;
      user-select: none;
      pointer-events: none;
    }
  }

  // Opinón del usuario
  #opinion {
    height: auto;
    min-height: 90px;
    max-height: 90px;
    overflow: hidden;
    width: 100%;
    margin: 25px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 170%;
    text-align: left;
    letter-spacing: -0.015em;
    color: var(--lila90);
    -webkit-font-smoothing: subpixel-antialiased;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-line-clamp: 4; 
    -webkit-box-orient: vertical;
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Max Width Container
    #max-width-container {
      width: 1000px;
    }
  }
`;

export default Container;
