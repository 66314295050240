import React from "react";
import Container from "./styled";

// Varibale para asigna las clases correctas dependiendo cual de laas dos opciones essta activada
var number = 1;

export default class changeOtrosSeguros extends React.Component {
  constructor(props) {
    super(props);
    this.state = { key: 1 };
  }

  // Al hacer click recibe la key y la categoria para cambiar el estado a la opcion correcta
  handleSelect(key, segurosCategory) {
    this.props.toggleSeguros(segurosCategory);
    this.setState({ key });
    number = key;
  }

  // Se ejectua al cargar el componente
  componentWillMount = () => {
    // Al cargar el componente number es 1 ya que por default el usuario tiene numero de poliza
    number = 1;
  };

  render() {
    return (
      <Container activeKey={this.state.key}>
        {/* Button */}
        <button
          type="button"
          eventKey={1}
          onClick={() => this.handleSelect(1, "otrosSegurosSi")}
          active={number}
        >
          {/* Text Button */}
          <p className={number == 1 && "activeP"}>Si</p>
          {/* Bottom Line */}
          <span className={number == 1 && "activeSpan"}></span>
        </button>
        {/* Button */}
        <button
          type="button"
          eventKey={2}
          onClick={() => this.handleSelect(2, "otrosSegurosNo")}
          active={number}
        >
          {/* Text Button */}
          <p className={number == 2 && "activeP"}>No</p>
          {/* Bottom Line */}
          <span className={number == 2 && "activeSpan"}></span>
        </button>
      </Container>
    );
  }
}
