import React from "react";
import Mapa from "./map";
import Container from "./styled";
import { Fade } from "react-awesome-reveal";

export default function MapBicicleterias() {
  return (
    <Container>
      <div id="max-width-container">
        {/* Titulo */}
        <Fade delay={100} duration={800} triggerOnce={true}>
          <h3>
            Bicicleterias <span>adheridas</span>
          </h3>
        </Fade>
        {/* Mapa */}
        <Mapa></Mapa>
      </div>
    </Container>
  );
}
