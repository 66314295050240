import React, { useRef } from 'react'
import ProgressBar from '../components/ProgressBar'
import FormSection from '../components/FormSection'
import { BiFolder, BiUser, BiCreditCard, BiData } from "react-icons/bi";
import DatosPersonalesForm from '../components/Forms/DatosPersonalesForm';
import { contrataReglas } from '../utils/formRules';
import { contrataForm } from '../utils/formInitialValues';
import { useForm } from '../hooks/useForm';
import InnerContainer from '../components/InnerContainer';
import Wrapper from '../components/Wrapper';
import AutogestionForm from '../components/Forms/AutogestionForm';
import BackButton from '../components/BackButton';
import MetodoPagoForm from '../components/Forms/MetodoPagoForm';
import Button from '../components/Button';
import FormaParteForm from '../components/Forms/FormaParteForm';

const Test = () => {

  const inputsRef = useRef({})

    // Custom Hooks
    const {
      inputValues,
      handleChange,
      handleValidation,
      formErrors,
      handleChangeExternal,
    } = useForm(handleSubmit, contrataReglas, contrataForm, {
      compania: null,
      tieneAccesorios: null,
      tab: null,
    });

  async function handleSubmit() {

  }
 
  const steps = [
    {
        title: 'Cotizá',
        class: 'hecho',
    },
    {
        title: 'Elegí',
        class: 'haciendo'
    },
    {
        title: 'Contratá',
        class: ''
    }
  ]

  return (
    <Wrapper>
        <ProgressBar title="Denunciá tu siniestro" steps={steps} />
        <InnerContainer>
          <BackButton prevStep={() => console.log("prev")}/>
          <FormSection icon={<BiUser />} title={"Datos Personales"} description={"Contanos de vos"} />
          <DatosPersonalesForm formValues={{inputValues, handleChange, formErrors }} inputsRef={inputsRef} />

          <FormSection icon={<BiFolder />} title={"Autogestión"} description={"Vamos a necesitar que subas algunos datos y archivos para validar tu vehículo."} />
          <AutogestionForm formValues={{inputValues, handleChange, formErrors }} inputsRef={inputsRef} compania="Prudencia" />

          <FormSection icon={<BiCreditCard />} title={"Método de Pago"} description={"Seleccioná tu metodo de pago preferido."} />
          <MetodoPagoForm formValues={{inputValues, handleChange, formErrors }} inputsRef={inputsRef} metodoDePago={"credito"}/>

          <FormaParteForm formValues={{inputValues, handleChange, formErrors}}/>

          <Button />
        </InnerContainer>
    </Wrapper>
  )
}

export default Test