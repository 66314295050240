import React, { useContext, useEffect, useState } from 'react'
import { usePlanes } from '../hooks/usePlanes'
import { useSwitch } from '../hooks/useSwitch'

const ContratacionContext = React.createContext()

export function useWizardContratacion() {
  return useContext(ContratacionContext)
}

export function ContratacionProvider({ children }) {
  
  const { planes, cotizacion, planSelected, handlePlan, bicicleteria } = usePlanes()
  const { step, prevStep, nextStep } = useSwitch()

  return (
    <ContratacionContext.Provider value={{planes, cotizacion, planSelected, handlePlan, bicicleteria, step, prevStep, nextStep }}>
      {children}
    </ContratacionContext.Provider>
  )
}