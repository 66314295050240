import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  width: 100%;

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
  }

  // Autogestión Titulo, Datos Personales Titulo, Datos Cotización Titulo y Datos de Pago Titulo
  .section {
    width: 100%;
    padding-top: 40px;
    margin-top: 48px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    border-top: 1px solid var(--grisClaro);

    // Título
    h4 {
      width: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: 500;
      font-size: 15px;
      line-height: 180%;
      text-align: left;
      letter-spacing: -0.015em;
      color: var(--negro);
      -webkit-font-smoothing: subpixel-antialiased;

      // Ícono
      span {
        margin: 5px 8px 0px 0px;
        font-size: 20px;
        color: var(--naranja100);
      }
    }

    // Texto
    p {
      width: 100%;
      margin: 12px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
      text-align: left;
      letter-spacing: -0.015em;
      color: var(--gris);
      -webkit-font-smoothing: subpixel-antialiased;

      a {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: var(--lila90);
        letter-spacing: -0.015em;
        transition: 0.2s all;

        // Hover
        :hover {
          opacity: 0.7;
        }
      }
    }
  }

  @media (max-width: ${breakpoints.desktop1200}) {
    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export default Container;
