import { enviarMail } from "../services/email";
import { mailStructure } from "./mailData";

// *** Enviar mail de formularios *** //
export const sendFormMail = async (inputValues, pasoSiguiente, mailContent, mailAttachments) => {
    const content = mailContent(inputValues)
    const mailData = mailStructure(content, mailAttachments)
    try {
      const sendMail = await enviarMail(mailData)
      if(sendMail.status === 200 && pasoSiguiente !== null){
        pasoSiguiente()
      }
    } catch (error) {
      console.log(error)
    }
};
