import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 64px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
  overflow: hidden;

  iframe {
    width: 100% !important;
    height: 550px !important;
    border: 0px;
    border-radius: 12px;
  }
`;

export default function Mapa() {
  return (
    <Container>
      <iframe src="https://www.google.com/maps/d/embed?mid=1fptT66IsCX7qjDYfvPr1QSvPA2sJ7Yf9&ehbc=2E312F"></iframe>
    </Container>
  );
}
