export const POLITICAS_TERMINOS = [
    {
      id: 1,
      pregunta: "Resguardar su privacidad es nuestra prioridad",
      respuesta: (
        <p>
              No le solicitaremos, a menos que sea imprescindible, información
              personal. Jamás compartiremos su información personal con un
              tercero, salvo con la compañía aseguradora con la que usted decida
              realizar la póliza o en caso de protección de nuestros derechos.
              <br />
              Si en algún momento quisiera eliminar o corregir sus datos, puede
              hacerlo usted mismo desde cualquiera de nuestras vías de
              comunicación (mail a info@seguroweb.com.ar, teléfono 011 4328
              6757, o vía{" "}
              <a href="https://www.facebook.com/SeguroWeb/" target="_blank">
                Facebook
              </a>
              ).
              <br />
              Nos comprometemos a que su información será bien resguardada y
              ponemos a disposición la edición o eliminación de la base, cuando
              usted lo requiera.
            </p>
      ),
      name: "resguardar",
    },
    {
      id: 2,
      pregunta: "Dirección Nacional de Protección de Datos Personales",
      respuesta: (
        <p>
        El titular de los datos personales tiene la facultad de ejercer el
        derecho de acceso a los mismos en forma gratuita y por intervalos
        no inferiores a seis meses, salvo que se acredite un interés
        legítimo al efecto, conforme lo establecido con el artículo 14,
        inciso 3, de la Ley Nº 25326.
        <br />
        La Dirección Nacional de Protección de Datos Personales, órgano de
        control de la Ley Nº 25.326, tiene la atribución de atender las
        denuncias y reclamos que se interpongan con relación al
        incumplimiento de las normas sobre protección de datos personales.
        <br />
        Para más información sobre: derechos de información, de acceso, de
        rectificación, actualización o supresión, información crediticia -
        derecho al olvido, puede ingresar a{" "}
        <a
          href="http://www.jus.gob.ar/datos-personales/tus-derechos.aspx"
          target="_blank"
        >
          tus derechos
        </a>
        .
      </p>
      ),
      name: "direccion-nacional",
    },
    {
      id: 3,
      pregunta: "Visitantes del sitio web",
      respuesta: (
        <p>
              SeguroBici toma información disponible desde los navegadores web y
              servidores, como la mayoría de los sitios con estadísticas.
            </p>
      ),
      name: "visitantes",
    },
    {
      id: 4,
      pregunta: "Protegemos sus datos personales",
      respuesta: (
        <p>
              SeguroBici utilizará la información de contacto y la pondrá a
              disposición de sus empleados y/o de las compañías de seguro que
              necesiten obtener dicha información con el objeto de procesarla o
              de contactarlo. Al utilizar nuestro sitio, usted da permiso para
              que nuestros empleados y/o compañías de seguros emisoras de póliza
              puedan tener acceso a su información. SeguroBici no compartirá,
              alquilará o venderá sus datos personales con nadie. SeguroBici se
              reserva el derecho de revelar información personal en caso de
              citación, orden judicial o solicitud gubernamental aplicable.
              <br />
              Si usted nos proporciona datos registrándose en nuestro sitio
              facilitando su dirección de email, SeguroBici puede enviarle
              correos electrónicos para brindarle información útil, servicios de
              newsletter, promociones y beneficios, solicitud de opiniones, etc.
              No nos gusta el SPAM, por eso, enviaremos sólo comunicaciones
              trascendentes o de interés consideradas por SeguroBici.
              <br />
              SeguroBici maximiza las medidas consideradas necesarias para
              protección contra accesos no autorizados, uso, alteración o
              destrucción de información personal.
            </p>
      ),
      name: "protegemos-datos-personales",
    },
    {
      id: 5,
      pregunta: "SeguroBici",
      respuesta: (
        <p>
              Recomendamos que lea los Términos de Uso del sitio
              segurobici.com.ar antes de su uso, ya que la utilización frecuente
              y continuada del mismo representará la aceptación de los términos.
              Estas condiciones del sitio segurobici.com.ar (de aquí en más, “el
              sitio”) así como las Políticas de Privacidad pueden ser
              modificadas por el mismo sin necesidad de aviso ni divulgación del
              hecho, por lo que es prudente que usted revise esta landing con
              asiduidad para estar al tanto de las actualizaciones. En caso de
              no coincidir con los términos de uso o con fragmentos de ellos, le
              recomendamos no continuar en el sitio.
            </p>
      ),
      name: "seguro-bici",
    },
    {
      id: 6,
      pregunta: "Sobre el producto ofrecido",
      respuesta: (
        <p>
              La información, valores y alcances de nuestros productos mostrados
              en el sitio están sujetos a posibles cambios relacionados con
              aéreas geográficas, casos extraordinarios y cuestiones personales
              del cliente. Los términos y condiciones informados en el sitio
              para cada producto de por SeguroComercial marca de Gestión y
              Servicios S.R.L. son a nivel general y puede que no todas las
              condiciones, aplicaciones, excepciones y beneficios estén
              plasmados.
              <br />
              Todo lo mostrado en el sitio está disponible en las jurisdicciones
              en las que está presente segurobici.com.ar con su correspondiente
              matrícula emitida por la Superintendencia de Seguros de la Nación.
              <br />
              Gestión y Servicios S.R.L. no garantiza que: el sitio
              segurobici.com.ar funcione ininterrumpidamente y en forma correcta
              ni que los errores visualizados sean corregidos, la navegación en
              el sitio segurobici.com.ar, el uso del servidor o los correos
              electrónicos enviados desde un dominio propio estén exentos de
              virus o software malicioso, el sitio segurobici.com.ar ofrezca
              productos o servicios que estén disponibles para usted o que las
              funciones del sitio sean utilizables por usted en la jurisdicción
              geográfica en que se encuentre.
              <br />
              Al realizar una cotización online en el sitio y dejar sus datos
              correspondientes a dirección de email o número telefónico, usted
              está aceptando unirse a nuestra base de datos y esa información
              personal puede utilizarse por SeguroBici marca de Gestión y
              Servicios S.R.L. para fines comerciales. A la vez, SeguroBici
              marca de Gestión y Servicios S.R.L. se compromete a no usar esa
              información para otras finalidades y a no vender, intercambiar,
              ceder o hacer públicos esos datos.
            </p>
      ),
      name: "producto-ofrecido",
    },
    {
      id: 7,
      pregunta: "Responsabilidad limitada",
      respuesta: (
        <p>
              Segurobici.com.ar se desliga de toda responsabilidad ante
              lesiones, reclamos, daños o acciones surgidos de: el accionar del
              sitio, los materiales del sitio o la imposibilidad de acceso al
              mismo y lo mismo para sitios de terceros vinculados con
              segurobici.com.ar; errores, virus digitales, omisiones o fallas
              generadas desde el sitio, excepto negligencia grave y concreta de
              segurobici.com.ar.
            </p>
      ),
      name: "responsabilidad",
    },
    {
      id: 8,
      pregunta: "Jurisdicción",
      respuesta: (
        <p>
              Los términos y condiciones aquí presentados están regidos por y
              siguen las leyes de la República Argentina y fueron redactados en
              base a lo establecido por la misma. Esto no implica que no pueda
              haber conflictos, desacuerdos, interpretaciones diferentes u
              opiniones variadas de estos términos, en cuyos casos el usuario
              deberá comunicarse de manera fehaciente con SeguroBici marca de
              Gestión y Servicios S.R.L. (Suipacha 245 6º piso, Capital Federal;
              teléfono: 011 4328 6757; dirección de email:{" "}
              <a href="mailto:info@gyssrl.com.ar">info@gyssrl.com.ar</a>). Si
              aún así persistiera el conflicto, tanto estos términos y
              condiciones como la compañía en sí están dispuestos a ser
              sometidos a los Tribunales correspondientes.
            </p>
      ),
      name: "jurisdiccion",
    }
  ];
  