import Header from "../../components/header/index";
import Footer from "../../components/footer/index";
import Headroom from "react-headroom";
import TitleSection from "../../components/TitleSection";
import Container from "./styled";
import InnerContainer from "../../components/InnerContainer";
import Section from "../../components/Section";
import Aside from "../../components/Aside";
import { Link as Boton } from "react-router-dom";
import { goToTop } from "../../utils/scrollToTop";
import DataContainer from "../../components/DataContainer";
import { Link } from "react-scroll";
import { POLITICAS_TERMINOS } from "../../constants/politicas-terminos";

const PoliticasTerminos = () => {
  return (
    <Container>
      {/* Canonical VivoMarketing */}
      <head>
        <link rel="canonical" href="https://segurobici.com.ar/contacto" />
      </head>
      {/* Header */}
      <Headroom>
        <Header></Header>
      </Headroom>
      {/* Titulo */}
      <TitleSection
        tituloSpan="Políticas y términos"
        titulo=" - Gestión y Servicios"
        tag="Institucional"
        descripcion="Actualizado a Dicimebre 2022"
      />
      {/* Formulario */}
      <InnerContainer>
        <DataContainer>
          {POLITICAS_TERMINOS.map((seccion) => {
            return (
              <Section
                title={seccion.pregunta}
                description={seccion.respuesta}
                name={seccion.name}
              />
            );
          })}
        </DataContainer>
        <Aside>
          <p>Índice</p>
          <Link
            to="resguardar"
            smooth={true}
            spy={true}
            duration={700}
            offset={-100}
          >
            Resguardar su privacidad
          </Link>
          <Link
            to="direccion-nacional"
            smooth={true}
            spy={true}
            duration={700}
            offset={-100}
          >
            Dir. Protección Datos Personales
          </Link>
          <Link
            to="visitantes"
            smooth={true}
            spy={true}
            duration={700}
            offset={-100}
          >
            Visitantes del sitio web
          </Link>
          <Link
            to="protegemos-datos-personales"
            smooth={true}
            spy={true}
            duration={700}
            offset={-100}
          >
            Protegemos sus datos personales
          </Link>
          <Link
            to="seguro-bici"
            smooth={true}
            spy={true}
            duration={700}
            offset={-100}
          >
            SeguroBici
          </Link>
          <Link
            to="producto-ofrecido"
            smooth={true}
            spy={true}
            duration={700}
            offset={-100}
          >
            Sobre el producto ofrecido
          </Link>
          <Link
            to="responsabilidad"
            smooth={true}
            spy={true}
            duration={700}
            offset={-100}
          >
            Responsabilidad limitada
          </Link>
          <Link
            to="jurisdiccion"
            smooth={true}
            spy={true}
            duration={700}
            offset={-100}
          >
            Jurisdicción
          </Link>
          <div>
            <p>Ver más</p>
            <Boton to="/sobre-nosotros" onClick={goToTop}>
              Sobre nosotros
            </Boton>
            <Boton to="/contacto">Contacto</Boton>
          </div>
        </Aside>
      </InnerContainer>
      {/* Footer */}
      <Footer marcas="active" pagina="institucional"></Footer>
    </Container>
  );
};

export default PoliticasTerminos;
