import styled from "styled-components";
import { breakpoints } from "../../../../../constants/media";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  background: #00000010;
  backdrop-filter: blur(10px);
  z-index: 100;
  animation-name: opacidad;
  animation-duration: 0.1s;
  animation-iteration-count: initial;
  animation-timing-function: ease-out;

  @keyframes opacidad {
    from {
      opacity: 0%;
    }
    50% {
      opacity: 50%;
    }
    to {
      opacity: 100%;
    }
  }

  // Pop Up Container
  .box {
    width: 600px;
    height: auto;
    max-height: 90vh;
    min-height: calc(540px - 17px - 17px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    margin: 0 auto;
    margin-top: calc(100vh - 85vh - 100px);
    background: #fff;
    box-shadow: 0px 5px 30px rgba(9, 30, 66, 0.15);
    border-radius: 6px;
    overflow: auto;
    padding: 17px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    animation-name: zoom;
    animation-duration: 0.15s;
    animation-iteration-count: initial;
    animation-timing-function: ease-out;
  }

  @keyframes zoom {
    from {
      opacity: 0%;
      transform: scale(0.7);
    }
    50% {
      opacity: 40%;
    }
    to {
      opacity: 100%;
      transform: scale(1);
    }
  }

  // Close Button Container
  .closeButtonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  // Clouse Button
  .closeButtonContainer a {
    cursor: pointer;
    font-size: 20px;
    color: var(--black);
    transition: 0.1s all;
  }

  .closeButtonContainer a:hover {
    color: var(--orange) !important;
  }

  // Title
  h3 {
    width: 100%;
    margin: 0px 0px 0px 0px;
    text-align: center;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: var(--black);
  }

  // Ingresar Código Container
  .ingresar-codigo-container {
    width: 100%;
    margin: 35px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Ingresar Código Container Text
  .ingresar-codigo-container p {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
  }

  // Ingresar Código Container Text 2
  .ingresar-codigo-container p:nth-child(2) {
    width: 40%;
    margin: 35px 0px 0px 0px;
    display: inline;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
  }

  // Ingresar Código Container Span
  .ingresar-codigo-container span {
    margin: 0px 7px 0px 0px;
    font-size: 25px !important;
    color: var(--orange);
  }

  // Input Container
  .input-container {
    width: 283.5px;
    margin: 35px 0px 0px 0px;
    position: relative;
  }

  // Input and Label
  .input-container input,
  .input-container label {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 98 !important;
  }

  // Input
  .input-container input {
    width: 100%;
    height: 35px;
    padding: 0px 15px 0px 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75em;
    color: var(--lightgray);
    border: 1px solid var(--lightgray);
    -webkit-appearance: none;
    box-shadow: 0;
    transition: 0.2s all;
    border-radius: 6px;
  }

  // Inputs Error
  .input-error {
    border: 1px solid red !important;
  }

  // Label
  .input-container label {
    position: absolute;
    top: 0.95em;
    left: 0;
    margin: 0px 0px 0px 15px;
    line-height: 1;
    font-size: 0.75em;
    font-weight: 400;
    color: var(--lightgray);
    cursor: text;
    z-index: 100;
  }

  // Input On Focus Label
  .input-container input:focus + label {
    top: -0.6em;
    background-color: var(--white);
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 10px;
    font-weight: 600;
    font-size: 0.7em;
    color: var(--orange) !important;
    z-index: 99 !important;
  }

  // Inputs Focus
  .input-container input:focus {
    border: 1px solid var(--orange);
    outline: none;
    z-index: 99 !important;
  }

  // Label Down
  .label-down {
    z-index: 97 !important;
  }

  // Label Top
  .label-top {
    top: -0.6em !important;
    background-color: var(--white);
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 10px !important;
    font-weight: 600 !important;
    font-size: 0.7em !important;
    color: var(--lightgray) !important;
    z-index: 99 !important;
  }

  // Input Type Number
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Input Type Number Firefox
  input[type="number"] {
    -moz-appearance: textfield;
  }

  // Olvidaste tu Código Container
  .olvidaste-tu-codigo-container {
    width: 100%;
    margin: 40px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Olvidaste tu Código Container Texto
  .olvidaste-tu-codigo-container p {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: inline;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
  }

  // Olvidaste tu Código Container Botón
  .olvidaste-tu-codigo-container a {
    margin: 20px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    font-family: "Raleway", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    color: var(--green);
    background-color: white;
    cursor: pointer;
    transition: 0.2s;
  }

  // Olvidaste tu Código Container Botón Ícono
  .olvidaste-tu-codigo-container a span {
    font-size: 17px;
    margin: 3px 7px 0px 0px;
  }

  // Consultar Botón
  button {
    padding: 12.5px 15px 12.5px 15px;
    margin: 80px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: var(--white);
    background-color: var(--orange);
    border: 0;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.2s all;
  }

  // Consultar Botón Ícono
  button span {
    margin: 0px 4px 0px 0px;
    font-size: 11px;
  }

  // Consultar Botón Hover
  button:hover {
    background-color: var(--lightorange);
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    right: 0;
    left: 0;
    box-shadow: 0px 10px 40px rgba(9, 30, 66, 0.25);
    top: 0;
  }
`;

export default Container;
