import axios from "axios"
import url from "../utils/url";

export const obtenerProductos = async () => {
    const productos = await axios.get(`${url}/api/productos/productos`);
    const productosList = productos.data.map((producto, index) => {
        return {
            'label': producto.descripcion,
            'value': producto.numero,
            'key': index + 1
        }
    })
    return productosList
}


