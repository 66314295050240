import * as React from "react";
import HomeContainer from "../containers/home-container";
import BotonFlotante from "../components/home/boton-flotante";

export default function HomePage({ data }) {
  return (
    <div>
      <BotonFlotante />
      <HomeContainer data={data}></HomeContainer>
    </div>
  );
}
