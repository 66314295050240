import axios from "axios";

const apiPrudenciaMonopatin = async (codigoPostal, condicionIVA, sumarAseg, CoberturaID) => {


    try {
        const informacion = {
            codigoPostal: codigoPostal,
            condicionIVA: condicionIVA,
            marca: "Olmo",
            modelo: "BMX500",
            sumaAseg: sumarAseg,
        };

        if (CoberturaID) {
            informacion.CoberturaID = CoberturaID;
        }

        const getPrudenciaApi = await axios.post(`${process.env.REACT_APP_URL}/api/api_prudencia_monopatin/prudenciaMonopatin`, informacion, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const prudenciaData = getPrudenciaApi.data;
        console.log(prudenciaData, "Respues api monopatin");
        return prudenciaData;

    } catch (error) {
        console.error("Esto es el error de api prudencia desde el FRONT", error);
    }


    return (
        <>
        </>
    );
}

export default apiPrudenciaMonopatin;
