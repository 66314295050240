import { createGlobalStyle } from "styled-components";
import Colors from "./colors";
import "material-symbols";

const GlobalStyle = createGlobalStyle`

:root{
   // Azules
   --azul100: ${Colors.azul100.toCSS()};
    --azul80: ${Colors.azul80.toCSS()};
    --azul60: ${Colors.azul60.toCSS()};
    --azul40: ${Colors.azul40.toCSS()};
    --azul20: ${Colors.azul20.toCSS()};
    --azul0: ${Colors.azul0.toCSS()};
    // Naranjas
    --naranja100: ${Colors.naranja100.toCSS()};
    --naranja80: ${Colors.naranja80.toCSS()};
    --naranja60: ${Colors.naranja60.toCSS()};
    --naranja40: ${Colors.naranja40.toCSS()};
    --naranja20: ${Colors.naranja20.toCSS()};
    --naranja0: ${Colors.naranja0.toCSS()};
    // Verdes
    --verde100: ${Colors.verde100.toCSS()};
    --verde90: ${Colors.verde90.toCSS()};
    --verde50: ${Colors.verde50.toCSS()};
    // Lila
    --lila100: ${Colors.lila100.toCSS()};
    --lila90: ${Colors.lila90.toCSS()};
    --lila80: ${Colors.lila80.toCSS()};
    // Celestes
    --celeste100: ${Colors.celeste100.toCSS()};
    --celeste90: ${Colors.celeste90.toCSS()};
    --celeste80: ${Colors.celeste80.toCSS()};
    // Sombras
    --sombraAzul: ${Colors.sombraAzul.toCSS()};
    --sombraNaranja: ${Colors.sombraNaranja.toCSS()};
    // Neutros
    --blanco: ${Colors.blanco.toCSS()};
    --negro: ${Colors.negro.toCSS()};
    --gris100: ${Colors.gris100.toCSS()};
    --gris90: ${Colors.gris90.toCSS()};
    --gris80: ${Colors.gris80.toCSS()};
    --gris: ${Colors.gris.toCSS()};
    // Borrar
    --grisClaro: ${Colors.grisClaro.toCSS()};
    --grisClaro2: ${Colors.grisClaro2.toCSS()};
    // Estados de visibilidad
    --verde: ${Colors.verde.toCSS()};
    --rojo: ${Colors.rojo.toCSS()};
    --rojoError: ${Colors.rojoError.toCSS()};
    // Notificaciones y botones
    --cielo: ${Colors.cielo.toCSS()};
}

// Todos los elementos
* {
    // Estilos para seleccion
    ::-moz-selection {
        color: var(--blanco);
        background: var(--naranja100);
    }

    ::selection {
        color: var(--blanco);
        background: var(--naranja100);
    }

    // Fuente por defecto
    font-family: 'Poppins', sans-serif;
    font-style: normal;

    p {
      font-family: 'DM Sans', sans-serif;
    }

    iframe {
      display: none !important;
    }
}

// Estilos de tipografías

// Titulos
h1, h2, h3, h4 {
  font-size: 42px;
  font-weight: 600;
  color: var(--negro);
}

h2 {
  font-size: 35px;
}

h3 {
    font-size: 27px;
}

h4 {
    font-size: 15px;
}

p {
    font-size: 14.5px;
    font-weight: 400;
    line-height: 27px;
}

// Buttons Styles
a, button {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  border-radius: 6px;
  text-decoration: none;
  cursor: pointer;
}

// Estilos Headroom
.headroom {
  z-index: 99 !important;
}

.headroom--unfixed {
  width: 100% !important;
  position: absolute !important; 
}

// Animaciones
.opacidad02 {
    animation-name: opacidad;
    animation-duration: 0.2s;
    animation-iteration-count: initial;
    animation-timing-function: ease-in;
}

.opacidad03 {
    animation-name: opacidad;
    animation-duration: 0.3s;
    animation-iteration-count: initial;
    animation-timing-function: ease-in;
}

.opacidad05 {
    animation-name: opacidad;
    animation-duration: 0.5s;
    animation-iteration-count: initial;
    animation-timing-function: ease-in;
}

.opacidad08 {
    animation-name: opacidad;
    animation-duration: 0.8s;
    animation-iteration-count: initial;
    animation-timing-function: ease-in;
}

.opacidad11 {
    animation-name: opacidad;
    animation-duration: 1.1s;
    animation-iteration-count: initial;
    animation-timing-function: ease-in;
}

.flotar {
    transform: translatey(0px);
	animation: flotar 4s ease-in-out infinite;
}

.topIn {
    animation-name: topIn;
    animation-duration: 0.7s;
    animation-iteration-count: initial;
    animation-timing-function: ease-in;
}

@keyframes opacidad {
    from {
      opacity: 0%;
    }
    10% {
      opacity: 0%;
    }
    50% {
      opacity: 50%;
    }
    to {
      opacity: 100%;
    }
  }

  @keyframes topIn {
    from {
      opacity: 0%;
      transform: translatey(450px);
    }
    10% {
      opacity: 0%;
    }
    50% {
      opacity: 50%;
    }
    to {
      opacity: 100%;
      transform: translatey(0px);
    }
  }

  @keyframes flotar {
	0% {
		transform: translatey(4px);
	}
	50% {
		transform: translatey(-12px);
	}
	100% {
		transform: translatey(4px);
	}
}
`;

export default GlobalStyle;
