import React from "react";
import Container from "./styled";
import { IoClose } from "react-icons/io5";
import { RiPagesLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "tippy.js/animations/scale-subtle.css";
import "tippy.js/animations/scale-extreme.css";
import disableScroll from "disable-scroll";
import DayJS from 'react-dayjs';

export default class pasoDos extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    // Disable scroll on
    this.props.toggleScroll != "" && disableScroll.on();
    return (
      <Container>
        <div className="box">
          <div className="closeButtonContainer">
            <a onClick={this.props.handleClose}>
              <IoClose />
            </a>
          </div>
          {/* Título */}
          <h3>Consultá tu denuncia</h3>
          {/* Subtitulo */}
          <div className="titulo-problema-container">
            <p>
              <span>
                <RiPagesLine></RiPagesLine>
              </span>
              Seguimiento de denuncia #{this.props.n_siniestro}
            </p>
            <p>
              Su denuncia se encuentra en proceso
            </p>
          </div>
          {/* Partes Estado Container */}
          <div className="partes-estados-container">
            <table>
              <thead>
                <tr>
                  <th>Estado</th>
                  <th>Fecha</th>
                </tr>
              </thead>
              <tbody>
                {this.props.historial.map(h => {
                  return(
                    <tr>
                      <td className="partes-celda">{h.descripcion}</td>
                      <DayJS element="td" className="estados-celda" format="DD/MM/YYYY-HH:mm">{h.fecha_modificacion}</DayJS>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {/* Partes Container */}
          </div>
        </div>
      </Container>
    );
  }
}
