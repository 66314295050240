import styled from "styled-components";
import { breakpoints } from "../../../../../constants/media";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  background: #00000010;
  backdrop-filter: blur(10px);
  z-index: 100;

  // Pop Up Container
  .box {
    width: 600px;
    height: auto;
    max-height: 90vh;
    min-height: calc(540px - 17px - 17px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    margin: 0 auto;
    margin-top: calc(100vh - 85vh - 100px);
    background: #fff;
    box-shadow: 0px 5px 30px rgba(9, 30, 66, 0.15);
    border-radius: 6px;
    overflow: auto;
    padding: 17px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
  }

  // Close Button Container
  .closeButtonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  // Clouse Button
  .closeButtonContainer a {
    cursor: pointer;
    font-size: 20px;
    color: var(--black);
    transition: 0.1s all;
  }

  .closeButtonContainer a:hover {
    color: var(--orange) !important;
  }

  // Title
  h3 {
    width: 100%;
    margin: 0px 0px 0px 0px;
    text-align: center;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: var(--black);
  }

  // Ingresar Código Container
  .titulo-problema-container {
    width: 100%;
    margin: 35px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Ingresar Código Container Text
  .titulo-problema-container p {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
  }

  // Ingresar Código Container Text 2
  .titulo-problema-container p:nth-child(2) {
    width: 40%;
    margin: 35px 0px 0px 0px;
    display: inline;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
  }

  // Ingresar Código Container Span
  .titulo-problema-container span {
    margin: 0px 7px 0px 0px;
    font-size: 25px !important;
    color: var(--orange);
  }

  // Partes Estados Container
  .partes-estados-container {
    width: 100%;
    margin: 35px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Partes Container
  .partes-container {
    width: 45%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0px 5% 0px 0px;
  }

  // Estados Container
  .estados-container {
    width: 45%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0px 0px 0px 5%;
  }

  // Estados Container Titulo y Partes Container Titulo
  .estados-container h4, .partes-container h4 {
    width: 100%;
    margin: 0px 0px 20px 0px;
  }

  // Partes Container Titulo
  .partes-container h4 {
    text-align: right;
  }

  // Estados Texto
  .estados-textos {
    width: 20%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  // Partes Container Texto y Estados Container Texto
  .partes-container p,
  .estados-textos p {
    margin: 7px 0px 7px 0px;
    font-family: "Raleway", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }

  // Primer Texto
  .primer-texto {
    margin: 0px 0px 7px 0px !important;
  }

  // Partes Container Texto
  .partes-celda {
    
    padding: 5px 0px 5px 0px;
    width: 100%;
    text-align: right;
  }

  .partes-celda:first-letter {
    text-transform: uppercase;
  }

  // Estados Container Texto
  .estados-celda {
    padding: 5px 13px 5px 13px;
    display: inline;
    font-size: 13px;
    font-weight: bold;
    color: var(--white);
    background-color: var(--gray);
    border-radius: 200px;
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    right: 0;
    left: 0;
    box-shadow: 0px 10px 40px rgba(9, 30, 66, 0.25);
    top: 0;
  }
`;

export default Container;
