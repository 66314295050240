import React, { useRef, useState } from "react";
import AutogestionForm from "../../Forms/AutogestionForm";
import DatosPersonalesForm from "../../Forms/DatosPersonalesForm";
import MetodoPagoForm from "../../Forms/MetodoPagoForm";
import FormSection from "../../FormSection";
import Button from "../../Button";
import Wrapper from "../../Wrapper";
import InnerContainer from "../../InnerContainer";
import BackButton from "../../BackButton";
import NumberFormat from "react-number-format";
import { useForm } from "../../../hooks/useForm";
import { contrataReglas } from "../../../utils/formRules";
import { contrataForm } from "../../../utils/formInitialValues";
import { useSocket } from "../../../context/SocketProvider";
import { BiFolder, BiUser, BiCreditCard, BiData } from "react-icons/bi";
import { useWizardContratacion } from "../../../context/WizardContratacionProvider";

const ContratacionPlan = () => {
  const socket = useSocket();
  const inputsRef = useRef({});

  const { nextStep, planSelected, planes, cotizacion, handlePlan, prevStep } =
    useWizardContratacion();

  // Estado para el switch de accesorios
  const [tieneAccesorios, setTieneAccesorios] = useState(false);

  // Función para cambiar el estado de los accesorios con el switch
  const handleAccesorios = () => {
    setTieneAccesorios(!tieneAccesorios);
  };

  // Fechas para la tarjeta
  const fecha = new Date();
  const obtenerMesActual = fecha.getMonth() + 1;
  const mesActual =
    obtenerMesActual.toString().length < 2
      ? "0" + obtenerMesActual
      : obtenerMesActual;
  const añoActual = fecha.getFullYear();

  // Estado para determinar la tab seleccionada de autogestión
  const [tab, setTab] = useState("factura");

  // Custom Hooks
  const {
    inputValues,
    handleChange,
    handleValidation,
    formErrors,
    handleChangeExternal,
  } = useForm(handleSubmit, contrataReglas, contrataForm, {
    compania: planSelected.nombre_compania,
    tieneAccesorios: tieneAccesorios,
    tab: tab,
  });

  async function handleSubmit() {}
  return (
    <div>
      <Wrapper>
        <InnerContainer>
          <BackButton prevStep={prevStep} />
          <div className="coberturas-container opacidad02">
            {/* Logo y Plan Container */}
            <div className="logo-plan-container">
              <img
                alt="Logo Seguro"
                src={process.env.PUBLIC_URL + planSelected.logo}
              ></img>
              <p>{planSelected.plan}</p>
            </div>
            {/* Checks Container */}
            <div className="checks-container">
              {planSelected.coberturas.map((cobertura) => {
                return (
                  <span className="check">
                    {cobertura.activo ? (
                      <span class="material-symbols-outlined">done</span>
                    ) : (
                      <span id="check-no" class="material-symbols-outlined">
                        close
                      </span>
                    )}
                    <p>{cobertura.cobertura_info?.nombre}</p>
                  </span>
                );
              })}
              {/* Precios Container */}
              <div className="precio-container">
                {/* Cuotas Container */}
                <div>
                  {planSelected.cuotas == "" ? null : (
                    <p
                      id={planSelected.precio == "" && "cuotas-bold"}
                      className={
                        planSelected.precioAnterior == "" && "cuotas-naranja"
                      }
                    >
                      {planSelected.cuotas}
                    </p>
                  )}
                  {planSelected.precioAnterior == "" ? null : (
                    <p id="precio-anterior">{planSelected.precioAnterior}</p>
                  )}
                </div>
                {/* Precio */}
                {planSelected.precio == "" ? null : (
                  <div>
                    {planSelected.oferta == "" ? null : (
                      <p>{planSelected.oferta}</p>
                    )}
                    <p>{planSelected.precio}</p>
                  </div>
                )}
                {planSelected.contratando == "" ? null : (
                  <div>
                    <p>{planSelected.contratando}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div id="datos-cotizacion-container">
            {/* Datos Cotización Título */}
            <div id="datos-cotización-titulo">
              <h4>
                <span>
                  <BiData></BiData>
                </span>
                Datos de la cotización
              </h4>
            </div>
            {/* Datos Container */}
            <div id="datos-container">
              {/* Suma Asegurada */}
              <div className="dato">
                <p>Suma Asegurada</p>
                <NumberFormat
                  value={localStorage.getItem("segurobici-sumaasegurada")}
                  className="foo"
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                  renderText={(value, props) => <p {...props}>{value}</p>}
                />
              </div>
              {/* Plan Seleccionado */}
              <div className="dato">
                <p>Plan</p>
                <p>
                  {planSelected.plan == "Plan Low" && "Low Cost"}
                  {planSelected.plan == "Plan Orbis" && "Orbis"}
                  {planSelected.plan == "Plan ATM" && "ATM"}
                  {planSelected.plan == "Plan Standard" && "Standard"}
                  {planSelected.plan == "Plan Gold" && "Gold"}
                  {planSelected.plan == "Plan Platinum" && "Platinum"}
                </p>
              </div>
              {/* Tipo de vehículo */}
              <div className="dato" id="dato-vehiculo">
                <p>Tipo de vehículo</p>
                <p>
                  {localStorage.getItem("segurobici-vehiculo") == "bicicleta" &&
                    "Bicicleta"}
                  {localStorage.getItem("segurobici-vehiculo") ==
                    "bicicleta (delivery)" && "Bicicleta (Delivery)"}
                  {localStorage.getItem("segurobici-vehiculo") ==
                    "bicicleta electrica" && "Bicicleta eléctrica"}
                  {localStorage.getItem("segurobici-vehiculo") ==
                    "monopatin electrico" && "Monopatín eléctrico"}
                </p>
              </div>
              {/* Provincia */}
              <div className="dato" id="dato-localidad">
                <p>Provincia</p>
                <p>{localStorage.getItem("segurobici-provincia")}</p>
              </div>
            </div>
          </div>
          <FormSection
            icon={<BiUser />}
            title={"Datos Personales"}
            description={"Contanos de vos"}
          />
          <DatosPersonalesForm
            formValues={{ inputValues, handleChange, formErrors }}
            inputsRef={inputsRef}
          />

          <FormSection
            icon={<BiFolder />}
            title={"Autogestión"}
            description={
              "Vamos a necesitar que subas algunos datos y archivos para validar tu vehículo."
            }
          />
          <AutogestionForm
            formValues={{ inputValues, handleChange, formErrors }}
            inputsRef={inputsRef}
            compania="Prudencia"
          />

          <FormSection
            icon={<BiCreditCard />}
            title={"Método de Pago"}
            description={"Seleccioná tu metodo de pago preferido."}
          />
          <MetodoPagoForm
            formValues={{ inputValues, handleChange, formErrors }}
            inputsRef={inputsRef}
            metodoDePago={"credito"}
          />

          <Button>Contratar</Button>
        </InnerContainer>
      </Wrapper>
    </div>
  );
};

export default ContratacionPlan;
