import Header from "../../components/header/index";
import Footer from "../../components/footer/index";
import Headroom from "react-headroom";
import TitleSection from "../../components/TitleSection";
import Container from "./styled";
import InnerContainer from "../../components/InnerContainer";
import Section from "../../components/Section";
import Aside from "../../components/Aside";
import { Link as Boton } from "react-router-dom";
import { goToTop } from "../../utils/scrollToTop";
import DataContainer from "../../components/DataContainer";
import { SOBRE_NOSOTROS } from "../../constants/sobre-nosotros";
import { Link } from "react-scroll";

const SobreNosotros = () => {
  return (
    <Container>
      {/* Canonical VivoMarketing */}
      <head>
        <link rel="canonical" href="https://segurobici.com.ar/contacto" />
      </head>
      {/* Header */}
      <Headroom>
        <Header></Header>
      </Headroom>
      {/* Titulo */}
      <TitleSection
        tituloSpan="Sobre nosotros"
        titulo=" - Gestión y Servicios"
        tag="Institucional"
        descripcion="Actualizado a Dicimebre 2022"
      />
      {/* Formulario */}
      <InnerContainer>
        <DataContainer>
          {SOBRE_NOSOTROS.map((seccion) => {
            return (
              <Section
                title={seccion.pregunta}
                description={seccion.respuesta}
                name={seccion.name}
              />
            );
          })}
        </DataContainer>
        <Aside>
          <p>Índice</p>
          <Link
            to="quienesSomos"
            smooth={true}
            spy={true}
            duration={700}
            offset={-100}
          >
            ¿Quíenes somos?
          </Link>
          <Link
            to="nuestrosObjetivos"
            smooth={true}
            spy={true}
            duration={700}
            offset={-100}
          >
            Nuestros objetivos
          </Link>
          <Link
            to="visionComercial"
            smooth={true}
            spy={true}
            duration={700}
            offset={-100}
          >
            Visión comercial
          </Link>
          <Link
            to="queHemosLogrado"
            smooth={true}
            spy={true}
            duration={700}
            offset={-100}
          >
            ¿Qué hemos logrado?
          </Link>
          <Link
            to="marcaLider"
            smooth={true}
            spy={true}
            duration={700}
            offset={-100}
          >
            Marca líder
          </Link>
          <Link
            to="blogyRedes"
            smooth={true}
            spy={true}
            duration={700}
            offset={-100}
          >
            Blog y redes sociales
          </Link>
          <Link
            to="servicioDeExcelencia"
            smooth={true}
            spy={true}
            duration={700}
            offset={-100}
          >
            Servicio de excelencia
          </Link>
          <Link
            to="disposicion"
            smooth={true}
            spy={true}
            duration={700}
            offset={-100}
          >
            Siempre a tu disposición
          </Link>
          <Link
            to="valores"
            smooth={true}
            spy={true}
            duration={700}
            offset={-100}
          >
            Nuestros valores
          </Link>
          <div>
            <p>Ver más</p>
            <Boton to="/politicas-y-terminos" onClick={goToTop}>
              Políticas y términos
            </Boton>
            <Boton to="/contacto">Contacto</Boton>
          </div>
        </Aside>
      </InnerContainer>
      {/* Footer */}
      <Footer marcas="active" pagina="institucional"></Footer>
    </Container>
  );
};

export default SobreNosotros;
