//import * as React from "react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../components/header/index";
import Cotizador from "../components/cotizador";
import Logos from "../components/home/logos/index";
import Cubrimos from "../components/home/cubrimos/index";
import Pasos from "../components/home/pasos/index";
import Referidos from "../components/home/referidos";
import CarouselBicicletas from "../components/home/carousel/index";
import Comunicacion from "../components/home/comunicacion";
import Beneficios from "../components/home/beneficios/index";
import Faqs from "../components/home/faqs/index";
import Footer from "../components/footer/index";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import url from "../utils/url";
import HomePage from "../pages/home-page";
// Librerias
import Headroom from "react-headroom";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
  overflow: hidden;
`;

export default function BicicleteriaEspecificaContainer() {
  const nombreBicicleteria = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [logo, setLogo] = useState("");
  const [bicicleteria, setBicicleteria] = useState("");
  const [showData, setShowData] = useState(false);
  const [notFound, setNotFound] = useState(false);

  // Trae la data de la bicicleteria según la URL. En caso de que no exista, manda a la página de 404.
  useEffect(() => {
    const checkBicicleteriaName = async () => {
      if (nombreBicicleteria) {
        const data = {
          bicicleteria: nombreBicicleteria.bicicleteria,
        };
        try {
          const bic = await axios.post(`${url}/api/bicicleterias`, data);
          setBicicleteria(bic.data.data[0]);
          setLogo(bic.data.image);
          setShowData(true);
        } catch (error) {
          setNotFound(true);
          console.log(error);
        }
      }
    };
    checkBicicleteriaName();
  }, [nombreBicicleteria]);

  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <Container>
      {showData ? (
        <>
          {/* Header */}
          <Headroom>
            <Header></Header>
          </Headroom>
          {/* Cotizador */}
          <Cotizador
            esBicicleteria={true}
            bicicleteria={bicicleteria}
            subtitle={`En ${bicicleteria.nombre}`}
            logo={logo}
            bicicleteriaId={bicicleteria.id}
          ></Cotizador>
          {/* Logos */}
          <Logos></Logos>
          {/* Cosas que cubre el seguro */}
          <Cubrimos></Cubrimos>
          {/* Pasos */}
          <Pasos></Pasos>
          {/* Referidos*/}
          <Referidos></Referidos>
          {/* Carousel */}
          <CarouselBicicletas></CarouselBicicletas>
          {/* Beneficios del seguro */}
          <Beneficios></Beneficios>
          {/* Comunicacion */}
          <Comunicacion></Comunicacion>
          {/* Preguntas frecuentes */}
          <Faqs></Faqs>
          {/* Footer */}
          <Footer link={location.pathname} marcas="active"></Footer>
        </>
      ) : notFound ? (
        <HomePage />
      ) : null}
    </Container>
  );
}
