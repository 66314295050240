import styled from "styled-components";
import { breakpoints } from "../../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 0px 0px 0px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // Dropdown Abierto y Dropdown Cerrado
  .dropdown-abierto,
  .dropdown-cerrado {
    width: 100%;
    max-width: calc(700px - 30px);
    max-height: 90px;
    margin: 0 auto;
    padding: 17px 15px 17px 15px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    pointer-events: none;
    border: 0px;
    background-color: var(--white);
    border-radius: 12px 12px 12px 12px !important;
  }

  // Logo y Plan Container
  .logo-plan-container {
    width: 100%;
    max-width: 115px;
    padding: 0px 24px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    border-right: 1px solid var(--lightgray2);
  }

  // Logo del Seguro
  .logo-plan-container img {
    width: 88%;
    max-height: 30px;
    height: auto;
    object-fit: contain;
  }

  // Nombre del Seguro
  .logo-plan-container p {
    margin: 10px 0px 0px 0px;
    font-weight: 600;
    font-size: 11px;
    text-align: center;
    color: var(--orange);
  }

  // Checks Container
  .checks-container {
    width: 100%;
    max-width: 500px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
  }

  .checks-container div {
    width: 100%;
    max-width: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Checks Container Icono
  .checks-container div span {
    width: 100%;
    font-size: 15.6px;
    text-align: center;
    color: var(--green);
  }

  .checks-container div p {
    width: 100%;
    text-align: center;
    font-size: 11px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 400;
    margin: 7px 0px 0px 0px;
  }

  // X Icono
  #check-no {
    color: red !important;
    font-size: 14px;
  }

  // Tiempo de transicion cuando se abre y se cierra
  .ReactCollapse--collapse {
    transition: height 350ms;
  }

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    // Logo y Plan Container
    .logo-plan-container {
      padding: 0px 0px 0px 0px;
      border-right: 0px solid;
    }

    .checks-container {
      display: none;
    }
  }
`;

export default Container;
