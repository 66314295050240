import styled from "styled-components";
import { breakpoints } from "../../../../constants/media";

const Container = styled.div`
  width: 261px;
  min-height: 307.61px;
  margin: 0px 0px 0px 0px;
  display: flex;
  justify-content: flex-start;
  border-radius: 12px;

  // Card Container
  .card-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid var(--grisClaro);
    background-color: var(--blanco);

    // Data Container
    #data-container {
      width: 100%;
    }
  }

  // Logo Title Container & Text Button Container
  #logo-title-container,
  #text-button-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #logo-title-container {
    height: 130px;
  }

  // Logo
  #logo-title-container img {
    width: auto;
    height: 30px;
    margin: 0px 0px 0px 0px;
    pointer-events: none;
  }

  // Title
  #logo-title-container p {
    width: 95%;
    margin: 24px 0px 0px 0px;
    padding: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    letter-spacing: -0.015em;
    color: #666666;
    pointer-events: none;


    display: flex;
    -webkit-box-pack: start;
    place-content: center flex-start;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    text-align: left;
    letter-spacing: -0.015em;
    color: var(--lila90);
    -webkit-font-smoothing: subpixel-antialiased;
  }

  // Text
  #text-button-container p {
    width: 80%;
    height: 36.04px;
    margin: 24px 0px 0px 0px;
    font-weight: 400;
    font-size: 12px;
    line-height: 154.7%;
    letter-spacing: -0.015em;
    color: #9f9fa1;
    pointer-events: none;
  }

  // Button
  #text-button-container a {
    margin: 24px 0px 0px 0px;
    padding: 12px 24px 12px 24px;
    background-color: #fffaf2;
    border-radius: 6px;
    border: 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 154.7%;
    text-align: center;
    letter-spacing: -0.015em;
    color: var(--naranja100);
    transition: 0.2s all;
  }

  // Button Hover
  #text-button-container a:hover {
    background-color: #fff4e3;
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    width: 480px;
    justify-content: center;
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    width: 350px;
  }
`;

export default Container;
