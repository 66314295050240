import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  max-width: 1920px;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  /* Confirmacion Container */
  .confirmacion-container {
    width: 100%;
    max-width: 1200px;
    margin: 45px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  /* Confirmacion Container Text */
  .confirmacion-container p {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
  }

  /* Confirmacion Container Span */
  .confirmacion-container span {
    margin: 0px 7px 0px 0px;
    font-size: 25px !important;
    color: var(--green);
  }

  // Confirmacion Container Text 2
  .confirmacion-container p:nth-child(2) {
    width: 35%;
    margin: 20px 0px 0px 0px;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
  }

  // Código Container
  .codigo-container {
    width: 100%;
    margin: 30px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Código Container Texto
  .codigo-container p {
    padding: 10px 20px 15px 20px;
    font-size: 28px;
    font-weight: 600;
    font-style: normal;
    color: #434343;
    border-radius: 8px;
    box-shadow: 0px 5px 20px rgba(9, 30, 66, 0.15);
  }

  .shimmer {
    background-image: linear-gradient(
      55deg,
      #c1c1c2 0%, #c1c1c2 40%,
      #d3d3d4 50%, #d3d3d4 52%,
      #c1c1c2 62%, #c1c1c2 100%
    );
    
    background-size: 400%;
    animation: shimmer 2500ms infinite;
  }

  @keyframes shimmer {
    from { background-position: 100% 100%; }
    to { background-position: 0 0; }
  }

  a {
    margin: 20px 0px 40px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: var(--orange);
    background-color: var(--white);
    border: 0px;
    border-radius: 6px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.2s all;
  }

  a span {
    margin: 0px 6px 0px 0px;
    font-size: 16px !important;
  }

  a:hover {
    color: var(--black) !important;
  }
`;

export default Container;
