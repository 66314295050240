import React from "react";
import Header from "../../components/header";
import Headroom from "react-headroom";
import Footer from "../../components/footer";
import PolizaStep from "../../components/SiniestrosSteps/PolizaStep";
import WizardSiniestros from "../../components/WizardSiniestros";
import InnerContainer from "../../components/InnerContainer";
import Container from "./styled";
import { SiniestrosProvider } from "../../context/WizardSiniestrosProvider";
import DenunciaStep from "../../components/SiniestrosSteps/DenunciaStep";
import ConfirmacionStep from "../../components/SiniestrosSteps/ConfirmacionForm";

const Siniestros = () => {

  return (
    <Container>
      {/* Header */}
      <Headroom>
        <Header></Header>
      </Headroom>
      <InnerContainer center={true}>
        <SiniestrosProvider>
          <WizardSiniestros>
            <PolizaStep />
            <DenunciaStep />
            <ConfirmacionStep />
          </WizardSiniestros>
        </SiniestrosProvider>
      </InnerContainer>

      <Footer marcas=""></Footer>
    </Container>
  );
};

export default Siniestros;
