import React, { useEffect } from "react";
import styled from "styled-components";
import Header from "../components/header/index";
import Titulo from "../components/forma-parte/titulo";
import FormularioSwitch from "../components/forma-parte/formulario/switch";
import Footer from "../components/footer/index";
import Headroom from "react-headroom";
import TitleSection from "../components/TitleSection";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
  overflow: hidden;
`;

export default function FormaParteContainer() {
  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <Container>
      {/* Canonical VivoMarketing */}
      <head>
        <link
          rel="canonical"
          href="https://segurobici.com.ar/forma-parte"
        />
      </head>
      {/* Header */}
      <Headroom>
        <Header></Header>
      </Headroom>
      {/* Titulo */}
      <TitleSection tituloSpan="Formá " titulo="parte" tag="Recursos humanos" descripcion="Comunicate con nosotros" />
      {/* Formulario */}
      <FormularioSwitch></FormularioSwitch>
      {/* Footer */}
      <Footer marcas="active" pagina="institucional"></Footer>
    </Container>
  );
}
