import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--grisClaro);

  // Max Width Container
  #max-width-container {
    width: 100%;
    max-width: 1290px;
    margin: 104px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
  }

  // Pasos Container
  .paso-container {
    width: 33.3%;
    max-width: 346px;
  }

  // Paso
  div div div {
    width: 100%;
    padding: 80px 0px 32px 0px;
    background-color: var(--white);
    box-shadow: 0px 5px 30px var(--sombraNaranja);
    border-radius: 16px;
    border: 1px solid var(--grisClaro);
    position: relative;
  }

  // Button Container
  a {
    text-decoration: none;
  }

  // Paso Image
  div div div img {
    display: block;
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    margin: auto;
    pointer-events: none;
  }

  div:nth-child(2) img {
    left: -20px;
  }

  div:nth-child(3) img {
    left: -10px;
  }

  // Paso Title
  .titulo-paso {
    text-align: center;
    font-size: 24px;
    margin: 8px 0px 0px 0px;
    font-weight: 700;
  }

  // Paso Text
  .texto-paso {
    text-align: center;
    max-width: 200px;
    min-height: 84px;
    padding: 16px 0px 0px 0px;
    margin: 0 auto;
    line-height: 28px;
    color: var(--negro);
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Max Width Container
    #max-width-container {
      max-width: 1000px;
    }

    // Pasos Container
    .paso-container {
      width: 28%;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      max-width: 680px;
    }

    // Pasos Container
    .paso-container {
      width: 31%;
    }

    // Paso Text
    .texto-paso {
      max-width: 82%;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    #max-width-container {
      max-width: 480px;
    }

    // Pasos Container
    .paso-container {
      width: 100%;
      max-width: 100%;
      margin: 0px 0px 0px 0px;
    }

    .paso-container:nth-child(2),
    .paso-container:nth-child(3) {
      margin: 70px 0px 0px 0px;
    }

    // Paso Text
    .texto-paso {
      min-height: 0px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    #max-width-container {
      max-width: 350px;
    }
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    // Max Width Container
    #max-width-container {
      max-width: 85%;
    }
  }
`;

export default Container;
