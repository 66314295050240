import React, { useState } from "react";
// Estilos
import Container from "./styled";
// Librerías
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
// Componentes
import ReferidosHeader from "./referidos/index";

import { useNavigate, Link } from "react-router-dom";
import Button from "../Button";

export default function Header({
  link,
  isReferidosHeaderOpen,
  toggleReferidosHeader,
}) {
  return (
    <Container name="header">
      {isReferidosHeaderOpen == true && (
        <ReferidosHeader
          toggleReferidosHeader={toggleReferidosHeader}
        ></ReferidosHeader>
      )}
      <div id="header-container">
        <div id="logo-data-container">
          <div id="logo-escudos-container">
            {/* Logo */}
            <h1>
              <Link to="/">
                <img
                  alt="Logo de SeguroBici"
                  src="/images/logos/gys/segurobici.svg"
                ></img>
              </Link>
            </h1>
            {/* Escudos */}
            <div id="escudos-container">
              {/* SeguroMotos */}
              <Tooltip
                anchorId="escudo-seguromotos"
                content="SeguroMotos"
                id="tooltip"
              ></Tooltip>
              <a
                target="_blank"
                href="https://seguromotos.com.ar/"
                id="escudo-seguromotos"
              >
                <img
                  alt="Escudo del logo de SeguroMotos"
                  src="/images/logos/gys/escudos/seguromotos.svg"
                ></img>
              </a>
              {/* SeguroHogar */}
              <Tooltip
                anchorId="escudo-segurohogar"
                content="SeguroHogar"
                id="tooltip"
              ></Tooltip>
              <a
                target="_blank"
                href="https://www.segurohogar.com.ar/"
                id="escudo-segurohogar"
              >
                <img
                  alt="Escudo del logo de SeguroHogar"
                  src="/images/logos/gys/escudos/segurohogar.svg"
                ></img>
              </a>
              {/* SeguroMascotas */}
              <Tooltip
                anchorId="escudo-seguromascotas"
                content="SeguroMascotas"
                id="tooltip"
              ></Tooltip>
              <a
                target="_blank"
                href="https://www.seguromascotas.com.ar/"
                id="escudo-seguromascotas"
              >
                <img
                  alt="Escudo del logo de SeguroMascotas"
                  src="/images/logos/gys/escudos/seguromascotas.svg"
                ></img>
              </a>
              {/* SeguroAuto */}
              <Tooltip
                anchorId="escudo-seguroauto"
                content="SeguroAuto"
                id="tooltip"
              ></Tooltip>
              <a
                target="_blank"
                href="https://auto.seguroweb.com.ar/"
                id="escudo-seguroauto"
              >
                <img
                  alt="Escudo del logo de SeguroAuto"
                  src="/images/logos/gys/escudos/seguroauto.svg"
                ></img>
              </a>
              {/* SeguroComercio */}
              <Tooltip
                anchorId="escudo-segurocomercio"
                content="SeguroComercio"
                id="tooltip"
              ></Tooltip>
              <a
                target="_blank"
                href="https://comercio.seguroweb.com.ar/"
                id="escudo-segurocomercio"
              >
                <img
                  alt="Escudo del logo de SeguroComercio"
                  src="/images/logos/gys/escudos/segurocomercio.svg"
                ></img>
              </a>
            </div>
          </div>
          {/* Numeros de telefono y redes Container */}
          <div id="numeros-redes-container">
            <Link to="/siniestros" className="boton">Denunciar siniestro</Link>
            {/*
            <a className="boton-telefono" href="tel:08102202373">
              0810-220-2373
            </a>
            <a className="boton-telefono" href="tel:1152632581">
              (011) 5263-2581
            </a>
            <div id="redes">
              <a
                href="https://www.instagram.com/segurobici/?hl=es"
                target="_blank"
              >
                <BsInstagram />
              </a>
              <a href="https://es-la.facebook.com/SeguroBici/" target="_blank">
                <FaFacebookF />
              </a>
            </div>
          */}
          </div>
        </div>
      </div>
    </Container>
  );
}
