import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  background-color: var(--celeste90);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

  // Social Media y Logos Container
  .social-media-logos-container {
    width: 100%;
    max-width: 1140px;
    padding: 48px 0px 40px 0px;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    align-content: flex-end;
    position: relative;
  }

  // Social Media Container
  .social-media-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  // Social Media Container Buttons
  .social-media-container a {
    margin-right: 14px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
    color: var(--naranja100);
    font-size: 19px;
    transition: 0.2s;
    cursor: pointer;
  }

  // Social Media Buttons Hover
  .social-media-container a:hover {
    opacity: 0.5;
  }

  // Logos Container
  .logos-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
  }

  // Logos Container Logos
  .logos-container img {
    display: block;
    margin-left: 35px;
    pointer-events: auto;
  }

  // Logo SW
  #logo-sw {
    max-width: 200px;
    height: 27.06px;
  }

  // Logo PDP
  #logoPDP {
    max-width: 95px;
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Social Media y Logos Container
    .social-media-logos-container {
      width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Social Media y Logos Container
    .social-media-logos-container {
      width: 680px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Social Media y Logos Container
    .social-media-logos-container {
      width: 480px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Social Media y Logos Container
    .social-media-logos-container {
      width: 400px;
    }

    // Logos Container
    .logos-container {
      width: 100%;
      margin: 56px 0px 0px 0px;
      justify-content: flex-end;
      flex-direction: row-reverse;

      // Logos
      img {
        margin-left: 0px;
        margin-right: 35px;
      }
    }
  }

  // 525px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile525}) {
    // Social Media y Logos Container
    .social-media-logos-container {
      max-width: 85%;
    }
  }
`;

export default Container;
