import React from "react";
import Container from "./styled";

const Section = ({ title, description, name }) => {
  return (
    <Container>
      <h3 name={name ? name : null} className="title">{title}</h3>
      <p id="completa">
        {description}
      </p>
    </Container>
  );
};

export default Section;
