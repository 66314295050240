import React, { useState } from "react";
import Header from "../../components/header/index";
import Footer from "../../components/footer/index";
import Headroom from "react-headroom";
import TitleSection from "../../components/TitleSection";
import Container from "./styled";
import InnerContainer from "../../components/InnerContainer";
import Section from "../../components/Section";
import { useForm } from "../../hooks/useForm";
import { formaParteReglas } from "../../utils/formRules";
import { formaParteForm } from "../../utils/formInitialValues";
import Aside from "../../components/Aside";
import { Link } from "react-router-dom";
import { goToTop } from "../../utils/scrollToTop";
import DataContainer from "../../components/DataContainer";
import FormaParteForm from "../../components/Forms/FormaParteForm";
import MessageReceived from "../../components/MessageReceived";

const FormaParte = () => {
  const [messageSent, setMessageSent] = useState(false);

  // Custom Hooks
  const {
    inputValues,
    handleChange,
    handleValidation,
    formErrors,
    handleChangeExternal,
  } = useForm(handleSubmit, formaParteReglas, formaParteForm);

  console.log(formErrors)

  async function handleSubmit() {
    try {
      //await sendFormMail(inputValues, pasoSiguiente, contactoMail);
      //pasoSiguiente()
      setMessageSent(true)
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container>
      {/* Canonical VivoMarketing */}
      <head>
        <link rel="canonical" href="https://segurobici.com.ar/contacto" />
      </head>
      {/* Header */}
      <Headroom>
        <Header></Header>
      </Headroom>
      {/* Titulo */}
      <TitleSection
        tituloSpan="Forma"
        titulo=" parte"
        tag="Recursos humanos"
        descripcion="Comunicate con nosotros"
      />
      {/* Formulario */}
      <InnerContainer>
        <DataContainer>
          {!messageSent ? (
            <>
              <Section
                title="¡Formá parte de Gestión y Servicios!"
                description={
                  "Completá el siguiente formulario y envianos un mensaje con tus motivaciones, pronto nos comunicaremos para charlar con vos."
                }
              />
              <FormaParteForm
                formValues={{
                  inputValues,
                  handleChange,
                  handleChangeExternal,
                  handleValidation,
                  formErrors,
                }}
              />
            </>
          ) : (
            <MessageReceived title="¡Recibimos tus datos con éxito!" description={"Pronto nos pondremos en contacto para charlar más con vos." }/>
          )}
         </DataContainer>        
         <Aside>
          <p>Email</p>
          <p id="plazos-texto">rrhh@gyssrl.com.ar</p>
          <div>
            <p>Ver más</p>
            <Link to="/contacto">Contacto</Link>
            <Link to="/sobre-nosotros">Sobre nosotros</Link>
            <Link to="/politicas-y-terminos">Políticas y términos</Link>
            <Link to="/referidos">Referidos</Link>
          </div>
        </Aside>
      </InnerContainer>
      {/* Footer */}
      <Footer marcas="active" pagina="institucional"></Footer>
    </Container>
  );
};

export default FormaParte;
