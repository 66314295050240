import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 1270px;
  margin: 168px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  // Formulario Titulo Container
  #form-titulo-container {
    width: 644px;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    z-index: 60;
  }

  // Titulos Container
  #titulos-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Titulos
  h1,
  h3 {
    width: 100% !important;
    margin: 0px 0px 8px 0px;
    color: var(--negro);
    letter-spacing: -0.015em;
  }

  h3 {
    margin: 0px 0px 0px 0px;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
  }

  // Formulario Container
  #form-container {
    width: 100%;
    max-width: 580px;
    margin: 56px 0px 0px 0px;
    padding: 32px 32px 32px 32px;
    display: flex;
    justify-content: center;
    position: relative;
    background-color: var(--blanco);
    border-radius: 12px;
    box-shadow: 0px 5px 30px var(--sombraNaranja);
    border: 1px solid var(--grisClaro);
  }

  // Formulario
  form {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Inputs Container
  #inputs-container {
    width: 100%;
    margin: 4px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Input Container
  #inputs-container .input-container {
    width: 48%;
    margin: 0px 0px 24px 0px;
    position: relative;
  }

  // Input and Label
  #inputs-container .input-container input,
  #inputs-container .input-container label {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 58 !important;
  }

  // Inputs
  #inputs-container .input-container input {
    width: 100%;
    height: 35px;
    padding: 0px 16px 0px 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75em;
    color: var(--gris);
    border: 1px solid rgb(152, 152, 154, 0.6);
    -webkit-appearance: none;
    box-shadow: 0;
    transition: 0.2s all;
    border-radius: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  // Label
  #inputs-container .input-container label {
    position: absolute;
    top: 0.95em;
    left: 0;
    margin: 0px 0px 0px 16px;
    line-height: 1;
    font-size: 0.75em;
    font-weight: 400;
    color: rgb(152, 152, 154, 0.6);
    cursor: text;
    z-index: 60;
  }

  // Input and Select On Focus Label
  #inputs-container .input-container input:focus + label {
    top: -0.6em;
    background-color: var(--blanco);
    padding: 0px 6px 0px 8px;
    margin: 0px 0px 0px 12px;
    font-weight: 500;
    font-size: 0.7em;
    color: var(--naranja100) !important;
    z-index: 59 !important;
  }

  // Inputs and Select Focus
  #inputs-container .input-container input:focus {
    color: var(--negro);
    border: 1px solid var(--naranja100);
    outline: none;
    z-index: 59 !important;
  }

  // Label Down
  .label-down {
    z-index: 57 !important;
  }

  // Label Top
  .label-top {
    top: -0.6em !important;
    background-color: var(--blanco);
    padding: 0px 6px 0px 8px;
    margin: 0px 0px 0px 12px !important;
    font-weight: 500 !important;
    font-size: 0.7em !important;
    color: rgb(152, 152, 154, 0.8) !important;
    z-index: 59 !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield !important;
  }

  #input-nombre-container input,
  #input-nombre-bicicleteria-container input {
    text-transform: capitalize !important;
  }

  // Botones Container
  #botones-container {
    width: 100%;
    margin: 24px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  #botones-container a {
    background-color: var(--blanco);
    color: var(--verde50);
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    text-decoration: none;
  }

  #botones-container a span {
    font-size: 17px;
    margin: 4px 8px 0px 0px;
  }

  #botones-container button {
    padding: 7px 24px 7px 24px;
    border-radius: 8px;
    border: 0px solid;
    color: var(--blanco);
    background-color: var(--naranja100);
    cursor: pointer !important;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  #botones-container button span {
    font-size: 20px;
    margin: -1px 0px 0px 12px;
  }

  #botones-container button:hover {
    background-color: var(--naranja40);
  }

  // Ilustración Container
  .ilustracion-container {
    width: calc(100% - 790px);
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    z-index: 40;
  }

  // Ilustración Imagen
  .ilustracion-container img {
    display: block;
    width: 100%;
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    max-width: 1000px;

    // Formulario Titulo Container
    #form-titulo-container {
      width: 575px;
    }

    // Formulario Container
    #form-container {
      max-width: 397px;
    }

    // Formulario
    form {
      max-width: 400px;
    }

    // Ilustración Container
    .ilustracion-container {
      width: 400px;
      min-width: 400px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    max-width: 100%;
    justify-content: center;

    // Formulario Titulo Container
    #form-titulo-container {
      justify-content: center;
    }

    // Titulo
    h1,
    h3 {
      text-align: center !important;
      margin: 0px 0px 0px 0px;
    }

    // Formulario
    form {
      margin: 0px 0px 0px 0px;
      max-width: 550px;
    }

    // Inputs Container
    form .input-container {
      min-width: none;
      max-width: 48%;
    }

    // Ilustración Container
    .ilustracion-container {
      display: none;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    max-width: 480px;

    // Titulos Container
    #titulos-container {
      justify-content: center;
      align-items: center;
      align-content: center;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    max-width: 350px;

    // Inputs Container
    form .input-container {
      min-width: 100%;
      max-width: 100%;
    }

    // Botones Container
    #botones-container {
      justify-content: center;
    }

    // Botón
    #botones-container button {
      width: 100%;
      padding: 16px 24px 16px 24px;
      order: 0;
    }

    #botones-container a {
      margin: 16px 0px 0px 0px;
      order: 1;
    }
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    width: 85%;
  }

  // 350px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile350}) {
    // Inputs Container
    form .input-container {
      min-width: 100%;
      max-width: 100%;
    }
  }
`;

export { Container };
