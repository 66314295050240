import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 80px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  border-top: 1px solid var(--grisClaro);

  // Max Width Container
  #max-width-container {
    width: 100%;
    max-width: 1270px;
    margin: 80px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }

  // Title
  h3 {
    width: 100%;
    margin: 0px 0px 0px 0px;
    text-align: center;
  }

  h3 span {
    color: var(--naranja100);
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    max-width: 1000px;
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    max-width: 680px;
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    max-width: 480px;
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    max-width: 350px;
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    width: 85%;
  }
`;

export default Container;
