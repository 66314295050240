import styled from "styled-components";
import { breakpoints } from "../../../../../constants/media";

const Container = styled.div`
  width: 100%;
  max-width: 1920px;
  background-color: var(--blanco);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .input-label {
    font-size: 13px;
    font-family: "DM Sans";
    color: var(--negro);
    font-weight: 500;
  }

  // Botón Atras Container
  .boton-atras-container {
    width: 100%;
    max-width: 1270px;
    margin: 0px 0px -32px 0px;
    display: flex;
    justify-content: flex-start;
    z-index: 98;
  }

  // Botón Atras
  .boton-atras-container button {
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 14px;
    background-color: var(--blanco);
    border: 0px;
    cursor: pointer;
    transition: 0.2s all !important;
  }

  // Botón Atras Icono
  .boton-atras-container button span {
    margin: 4px 10px 0px 0px;
    font-size: 20px;
    transform: translate(0, 0);
    transition: 0.2s all !important;
  }

  // Botón Atras Hover
  .boton-atras-container button:hover {
    color: var(--lila90);
  }

  // Botón Atras Hover Iconok
  .boton-atras-container button:hover > span {
    color: var(--lila90);
    transform: translate(-10px, 0);
  }

  // Max Width Coberturas Container
  #max-width-coberturas-container {
    width: 100%;
    max-width: 911px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Coberturas Container
  .coberturas-container {
    width: 100%;
    max-width: 861px;
    max-height: 115px;
    margin: 80px 0px 0px 0px;
    padding: 24px 24px 24px 24px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    pointer-events: none;
    border: 1px solid var(--grisClaro);
    background-color: var(--blanco);
    border-radius: 16px !important;
    box-shadow: 0px 5px 30px var(--sombraNaranja);
    transition: 0.2s all;
  }

  // Logo y Plan Container
  .logo-plan-container {
    width: 100%;
    height: 67px;
    max-width: 115px;
    min-height: 67px;
    padding: 0px 24px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    border-right: 1px solid var(--grisClaro);
  }

  // Logo del Seguro
  .logo-plan-container img {
    width: 100%;
    max-height: 30px;
    height: auto;
    object-fit: contain;
  }

  // Plan del Seguro
  .logo-plan-container p {
    margin: 10px 0px 0px 0px;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: var(--naranja100);
    line-height: 10px;
  }

  // Checks Container
  .checks-container {
    width: 100%;
    max-width: 721px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
  }

  // Checks Container Icono
  .checks-container span {
    width: 100%;
    max-width: 95px;
    font-size: 24px;
    text-align: center;
    color: var(--verde50);
  }

  // Checks Container Text
  .checks-container span p {
    margin: 6px 0px 0px 0px;
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;

    .material-symbols-outlined {
      font-variation-settings: "FILL" 0, "wght" 700, "GRAD" 0, "opsz" 40;
    }
  }

  // X Icono
  #check-no {
    font-size: 22px;
    color: var(--rojo) !important;
  }

  // Checks Container Texto
  .checks-container span p {
    margin: 0px 0px 0px 0px;
    font-weight: bold;
    line-height: normal;
    font-size: 12px !important;
    text-align: center;
    color: var(--negro);
  }

  // Precio Container
  .precio-container {
    width: 100%;
    max-width: 135px;
    min-width: 135px;
    margin: 0px 0px 0px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Informacion de precios
  .precio-container div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  // Informacion de precios text
  .precio-container div p {
    margin: 0px 3px 0px 3px;
  }

  // Cuotas
  .precio-container div:nth-child(1) p {
    margin: 0px 0px 0px 0px;
    font-weight: bold;
    line-height: normal;
    font-size: 12px !important;
    text-align: center;
    color: var(--gris);

    .cuotas-naranja {
      color: var(--naranja100) !important;
    }

    #cuotas-bold {
      font-weight: 600 !important;
    }
  }

  // Precio anterior
  #precio-anterior {
    margin: 0px 0px 0px 3px;
    text-decoration: line-through !important;
  }

  // Precio y oferta
  .precio-container div:nth-child(2) p {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: var(--naranja100);
    margin: 4px 3px 4px 3px !important;
  }

  // Contratando
  .precio-container div:nth-child(3) p {
    font-weight: 600;
    font-size: 11px;
    text-align: center;
    color: var(--lila90);
    line-height: normal;
  }

  // Formulario
  form {
    width: 100%;
    max-width: 1270px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Input Container & Select Container
  .input-container,
  .select-container {
    width: 283.5px;
    margin: 30px 22px 0px 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  // Input & Select
  .input-container input,
  .input-container textarea,
  .select-container select {
    width: 100%;
    padding: 14px 20px 14px 20px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    font-weight: 400;
    color: #949494 !important;
    border: 1.7px solid var(--grisClaro);
    background-color: var(--blanco);
    -webkit-appearance: none;
    box-shadow: 0;
    transition: 0.2s all;
    border-radius: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 58 !important;

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;

      :invalid {
        color: #949494 !important;
      }
    }

    // Firefox
    [type="number"] {
      -moz-appearance: textfield !important;
    }
  }

  .input-container input::placeholder {
    color: #949494 !important;
  }

  // Input & Select Focus
  .input-container input:focus,
  .select-container select:focus {
    border: 1.7px solid var(--naranja100);
    outline: none;
    z-index: 59 !important;
  }

  // Input Error
  .input-error {
    border: 1px solid #C42200 !important;
  }

  // Select Container
  .select-container {
    cursor: pointer;

    // Select
    select {
      cursor: pointer;
    }
  }

  // Select Default Arrow
  .select-container select::-ms-expand {
    display: none;
  }

  // Select Option
  .select-container select * {
    color: var(--negro);
  }

  // Select Flecha Container
  .flecha-select-container {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 16px;
    top: calc(50% - 10px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    background-color: var(--blanco);
    z-index: 59;
    transition: 0.2s all;
  }

  // Select Arrow
  .select-container div i {
    width: 7px;
    height: 7px;
    margin: -4px 0px 0px 0px;
    display: block;
    border-left: 3px solid rgb(152, 152, 154, 0.6);
    border-bottom: 3px solid rgb(152, 152, 154, 0.6);
    transform: rotate(-45deg);
    border-radius: 10%;
    transition: 0.3s all;
  }

  .select-container select:focus ~ .flecha-select-container i {
    margin: 3px 0px 0px 0px !important;
    transform: rotate(135deg) !important;
  }

  // Input Error Text
  .input-container span,
  .select-container span {
    font-size: 12px !important;
    color: var(--rojoError);
  }

  .input-container .info-input,
  .select-container span {
    font-size: 12px !important;
    color: var(--gris);
  }


  // Input Código Postal
  #input-codigo-postal-container,
  #input-color-container,
  #select-tamaño-cuadro-container {
    margin: 30px 0px 0px 0px;
  }

  // Input Código Área
  #input-codigo-area-container {
    width: 90px;
  }

  // Input Teléfono y Input Vencimiento Tarjeta
  #input-telefono-container,
  #input-vencimiento-container {
    width: 171.5px;
    margin: 30px 0px 0px 0px;
  }

  // Input CBU
  #input-cbu-container {
    margin: 40px 0px 0px 0px;
  }

  // Autogestión Container, Datos Personales Container, Datos Pago Container y Datos Cotización Container
  .container-seccion {
    width: 100%;
    margin: 48px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    border-top: 1px solid var(--grisClaro);
  }

  textarea {
    width: 200px;
  }

  // Datos Cotización Container
  #datos-cotizacion-container {
    // Datos Container
    #datos-container {
      width: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;

      // Dato
      .dato {
        width: fit-content;
        margin: 24px 32px 0px 0px;
      }

      // Dato Titulo
      .dato p {
        margin: 0px 0px 0px 0px;
        font-size: 12px;
        font-weight: 400;
        color: var(--gris);
      }

      // Dato Texto
      .dato p:nth-child(2) {
        margin: 4px 0px 0px 0px;
        font-size: 14px;
        font-weight: 600;
        color: var(--lila90);
      }
    }
  }

  // Autogestión Titulo, Datos Personales Titulo, Datos Cotización Titulo y Datos de Pago Titulo
  #autogestion-titulo,
  #datos-personales-titulo,
  #datos-de-pago-titulo,
  #datos-cotización-titulo {
    width: 100%;
    margin: 40px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    // Título
    h4 {
      width: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: 500;
      font-size: 15px;
      line-height: 180%;
      text-align: left;
      letter-spacing: -0.015em;
      color: var(--negro);
      -webkit-font-smoothing: subpixel-antialiased;

      // Ícono
      span {
        margin: 5px 8px 0px 0px;
        font-size: 20px;
        color: var(--naranja100);
      }
    }

    // Texto
    p {
      width: 100%;
      margin: 12px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
      text-align: left;
      letter-spacing: -0.015em;
      color: var(--gris);
      -webkit-font-smoothing: subpixel-antialiased;

      a {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: var(--lila90);
        letter-spacing: -0.015em;
        transition: 0.2s all;

        // Hover
        :hover {
          opacity: 0.7;
        }
      }
    }
  }

  #datos-de-pago-titulo {
    margin: 40px 0px 32px 0px;
  }

  // Autogestión Marca Modelo
  #autogestion-marca-modelo {
    width: 100%;
    margin: 18px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  // Autogestión Accesorios
  #autogestion-accesorios {
    width: 100%;
    margin: 48px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    // Texto Switch Container
    #texto-switch-container {
      width: 100%;
      margin: 0px 0px 16px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      // Texto
      p {
        width: 192px;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 180%;
        text-align: left;
        letter-spacing: -0.015em;
        color: var(--gris);
        -webkit-font-smoothing: subpixel-antialiased;
      }

      // Switch
      .switch-accesorios {
        margin: 0px 0px 0px 10px;
        transform: scale(0.8);
      }
    }
  }

  // Autogestión Foto Video Container
  #autogestion-foto-video-container {
    width: 100%;
    margin: 10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  // Autogestión Archivos
  #autogestion-archivos {
    width: 100%;
    margin: 40px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    // Texto
    #texto-factura {
      width: 100%;
      margin: 0px 0px 40px 0px;
      padding: 0px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
      text-align: left;
      letter-spacing: -0.015em;
      color: var(--gris);
      -webkit-font-smoothing: subpixel-antialiased;
    }

    // Archivo Valor Container
    #archivo-valor-container {
      width: 100%;
      margin: 10px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
    }
  }

  // Tabs Container
  #tabs-container {
    width: 100%;
    margin: -16px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    // Tab
    a {
      width: auto;
      margin: 16px 16px 0px 0px;
      padding: 10px 20px 10px 20px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 12.5px;
      line-height: 180%;
      text-align: center;
      letter-spacing: -0.015em;
      color: #888;
      -webkit-font-smoothing: subpixel-antialiased;
      background-color: #f6f6f6;
      border: 1px solid var(--grisClaro);
      transition: 0.2s all;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;

      // Logo MercadoLibre
      img {
        height: 10px;
        pointer-events: none;
        user-select: none;
      }

      // Hover
      :hover {
        opacity: 0.5;
      }
    }

    // Tab Activa
    .tab-activa {
      border: 1px solid var(--lila100);
      box-shadow: inset 0px 0px 0px 1px var(--lila90);

      // Hover
      :hover {
        opacity: 1 !important;
      }
    }
  }

  // Pago Tarjeta Container
  #pago-tarjeta-container {
    width: 100%;
    margin: 10px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Datos Tarjeta Credito
  #datos-tarjeta-credito {
    width: 70%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Tarjeta Credito Container
  #tarjeta-credito-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  // Botón Contratá Container
  #boton-contrata-container {
    width: 100%;
    margin: 50px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
  }

  .inputs-cont {
    display: flex;
    flex-wrap: wrap;
    //background-color: blue;
  }

  // POLIZAS

  .poliza-container {
    width: 100%;
    border-top: 1px solid var(--grisClaro);
    background-color: var(--blanco);
    margin-top: 40px;
  }

  .poliza h4 {
    margin: 0;
  }

  .poliza {
    width: 400px;
    border: 1px solid var(--grisClaro);
    background-color: var(--blanco);
    box-sizing: border-box;
    padding: 20px;
    margin-top: 20px;
  }

  .poliza p {
      width: 100%;
      margin: 12px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
      text-align: left;
      letter-spacing: -0.015em;
      color: var(--gris);
      -webkit-font-smoothing: subpixel-antialiased;

      a {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: var(--lila90);
        letter-spacing: -0.015em;
        transition: 0.2s all;

        // Hover
        :hover {
          opacity: 0.7;
        }
      }
    }

  .poliza-header {
    border-bottom: 1px solid var(--grisClaro);
  }

  // Botón Contratá
  #boton-contrata {
    width: auto;
    //margin: 30px 0px 0px 0px;
    padding: 14px 26px 14px 26px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: var(--naranja100);
    border-radius: 8px;
    border: 0;
    text-decoration: none;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 154.7%;
    text-align: center;
    color: var(--blanco);
    transition: 0.2s all;

    // Ícono
    span {
      margin: 1px 0px 0px 8px;
      font-size: 20px;
      transition: 0.2s all;
    }

    :hover {
      background-color: var(--naranja20);

      span {
        transform: translate(8px, 0px);
      }
    }
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 1000px;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 1000px;
    }

    // Formulario
    form {
      max-width: 1000px;
    }

    // Input Container y Select Container
    .input-container,
    .select-container {
      width: 238.5px;
      margin: 30px 15px 0px 0px;
    }

    // Input Telefono
    #input-telefono-container {
      width: 134.5px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 680px;
      margin: 40px 0px 0px 0px;
    }

    #max-width-coberturas-container {
      max-width: 680px;
    }

    // Coberturas Container
    .coberturas-container {
      max-width: 290px;
      margin: 40px 0px 0px 0px;
    }

    // Checks Container
    .checks-container {
      width: 150px;
    }

    // Checks Container Span
    .checks-container span {
      display: none;
    }

    // Formulario
    form {
      max-width: 680px;
    }

    // Input Container, Select Container, Input Código Area, Input Telefono y Input Vencimiento
    .input-container,
    .select-container,
    #input-codigo-area-container,
    #input-telefono-container,
    #input-vencimiento-container {
      width: 216.5px;
      max-width: 216.5px;
    }

    // Input Código Postal
    #input-codigo-postal-container,
    #input-color-container {
      margin: 30px 15px 0px 0px;
    }

    // Input CUIT DNI, Input Telefono y Código de Área Container
    #input-cuit-dni-container,
    #input-telefono-container,
    #input-codigo-area-container,
    #input-rodado-container,
    #input-transmision-container,
    #input-vencimiento-container {
      margin: 30px 0px 0px 0px;
    }

    // Datos Tarjeta
    #datos-tarjeta-credito {
      width: 100% !important;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 480px;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 480px;
    }

    // Formulario
    form {
      max-width: 480px;
    }

    // Datos Vehiculo Container
    .datos-vehiculo-container {
      width: 100%;
    }

    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    .select-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container,
    #input-rodado-container {
      width: 232.5px;
      max-width: 232.5px;
      margin: 30px 15px 0px 0px;
    }

    // Input Apellido, Input Código Postal, Input Código Área, Input Banco Emisor, Input Titular, Input CBU
    #input-apellido-container,
    #input-codigo-postal-container,
    #input-codigo-area-container,
    #input-modelo-container,
    #input-banco-emisor-container,
    #input-titular-container,
    #input-cbu-container,
    #input-color-container {
      margin: 30px 0px 0px 0px;
    }

    #input-apellido-container,
    #input-banco-emisor-container,
    #input-titular-container {
      text-transform: capitalize !important;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 380px;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 380px;
    }

    // Formulario
    form {
      max-width: 380px;
    }

    // Datos Cotización
    #datos-cotizacion {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
    }

    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    .select-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container {
      width: calc(50% - 7.5px) !important;
      max-width: calc(50% - 7.5px) !important;
    }

    #datos-tarjeta-credito {
      width: 100%;
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 85%;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 85%;
      justify-content: center;
    }

    // Logo y Plan Container
    .logo-plan-container {
      padding: 0px 0px 0px 0px;
      border-right: 0px;
    }

    // Checks Container
    .checks-container {
      display: none;
    }

    // Coberturas Container
    .coberturas-container {
      max-width: 115px;
    }

    // Formulario
    form {
      max-width: 85%;
    }

    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container,
    .select-container {
      width: 100% !important;
      max-width: 100% !important;
      margin: 30px 0px 0px 0px !important;
    }
  }

  // 350px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile350}) {
    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container {
      width: 100%;
      max-width: 100%;
      margin: 30px 0px 0px 0px;
    }
  }
`;

export default Container;
